export const styles = ({ palette }) => ({
  menuRoot: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  txPrefMenuWhite: {
    color: 'white',
    width: 'inherit',
  },
  txPrefMenuBlack: {
    color: palette.slateGrey,
    width: 'inherit',
  },
  menuHolder: {
    marginTop: -5,
    display: 'inline',
    height: 'inherit',
    width: 24,
  },
  menuSubHead: {
    paddingLeft: 7,
    paddingRight: 5,
    paddingBottom: 5,
    color: palette.koalaGrey,
  },
  listIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  listText: {
    paddingLeft: 10,
  },
  popOver: {
    padding: 20,
  },
  iconFiller: {
    width: 24,
  },
  colorBar: {
    width: 10,
    height: 10,
    marginLeft: 5,
  },
  parentList: {
    paddingBottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
});
