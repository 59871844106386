import { PrefType } from './type';

export const preferenceData: PrefType[] = [
  {
    label: 'Allow editing amounts of downloaded transactions',
    field: 'editDownloaded',
    id: 'txn-pref-edit-downloaded-txns',
    fflag: 'prefEditDownloaded',
  },
  {
    label: 'Allow deletion of downloaded transactions',
    field: 'deleteDownloaded',
    id: 'txn-pref-delete-downloaded-txns',
    fflag: 'prefDeleteDownloaded',
  },
  {
    label: 'Automatically save edited transactions',
    field: 'autoSave',
    id: 'txn-pref-auto-save-edited-txns',
    fflag: 'prefAutoSaveEditedTransactions',
  },
  {
    label: 'Show separate Money In and Money Out columns',
    field: 'doubleAmounts',
    id: 'txn-pref-show-money-in-money-out',
    fflag: 'prefMoneyInOutColumn',
  },
  {
    label: 'Automatically search in register as you type in search field',
    field: 'autoSearch',
    id: 'txn-pref-auto-search',
    fflag: 'prefAutoSearch',

  },
  {
    label: 'Wrap text in transaction list fields',
    field: 'wrapText',
    id: 'txn-pref-wrap-text-in-txn-field',
    fflag: 'prefWrapText',
  },
  {
    label: 'Show long form of category names (show full path with parent names)',
    field: 'longCats',
    id: 'txn-pref-show-long-category-names',
    fflag: 'prefLongCatName',
  },
  {
    label: 'Show currency symbol on amounts in the transaction list',
    field: 'showCurrencySymbol',
    id: 'txn-pref-show-currency-symbol-on-amounts',
    fflag: 'prefCurrencySymbolTransactionList',
  },
  {
    label: 'After adding a new transaction include new transaction entry above all existing transactions',
    field: 'continuousCreate',
    id: 'txn-pref-new-txn-entry-above-txns',
    fflag: 'prefNewTransactionEntry',
  },
  {
    label: 'Show colored icon in transaction list instead of full account name',
    field: 'showAccountColors',
    id: 'txn-pref-show-account-colors',
    fflag: 'prefColoredAccounts',
  },
];
