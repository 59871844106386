const styles = ({ palette }) => ({
  txRow: {
    display: 'flex',
    flexFlow: 'row',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
  },
  txField: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&.cleared': {
      fontWeight: 500,
    },
  },
  listRoot: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 2,
  },
  listFooter: {
    overflow: 'visible',
  },
  selectedItem: {
    backgroundColor: palette.blue5,
  },
  colsWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
  },
  divListItem: {
    background: palette.shinyGrey,
    textAlign: 'center',
  },
  titleWrapper: {
    width: '100%',
    background: palette.blue4,
    textAlign: 'center',
  },
  divTxnRow: {
    paddingLeft: 2,
    paddingRight: 2,
    overflow: 'hidden',
  },
  divListItemColumn: {
    wordBreak: 'keep-all',
    paddingLeft: 4,
    paddingRight: 4,
  },
});

export default styles;
