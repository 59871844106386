import React, { useState, FC, useEffect, useRef, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ButtonBase from '@mui/material/ButtonBase';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import FastCatIcon from '@mui/icons-material/FlashOn';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilterListIcon from '@mui/icons-material/FilterListRounded';
import FileDownload from '@mui/icons-material/GetApp';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import RootState from 'companion-app-components/utils/redux-store/rootState';

import useQPreferences from 'components/QPreferences/useQPreferences';
import QIconButton from 'components/QIconButton';
import SearchBox from 'components/SearchBox';
import FileImportDialog from 'components/Dialogs/FileImportDialog';
import QFilterSelect from 'components/QFilterSelect';
import useQCurrency from 'components/QCurrency/useQCurrency';
import useQDialogs from 'components/QDialogs/useQDialogs';
import { showTxnDetailsDialog } from 'components/Transactions/DetailsDialog/actions';
import { isMobile, isTablet } from 'react-device-detect';
import { FilterObject } from 'components/QFilterSelect/types';
import { countFilterItemsForDisplay } from 'components/QFilterSelect/utils';
import ScheduleViewMenu from './scheduleViewMenu';
import SearchHelpBox from './SearchHelpBox';
import { styles } from './styles';
import QTip from '../../QuickenControls/QTip';


const useStyles = makeStyles()(styles as Record<string, any>);

// ==============================================================
//
// The control bar at the top of the Transaction list (register)
//
// props:
//
// onAdd   - called when add/new transaction pushed
//
//

interface RegControlBarProps {
  onFastCategorize?: (event: MouseEvent<HTMLButtonElement>) => void;
  onSearch: (query: string) => void;
  downloadFile?: () => void;
  filter?: string;
  accountsFilter: any;
  acctTxns: any;
  transactionListMetrics: any;
  scheduledTransactionsInRegister?: boolean;
  reminderSetting?: string | number;
  onReminderChange?: (value: string | number) => void;
  noNew?: boolean;
  showNewTxn: () => void;
  isWindowsDataset: boolean;
  onApplyFilter: (filters: FilterObject) => void;
  filterObject: any;
  defaultAccountId?: string;
  showDebtErrorMessage?: boolean;
}

const RegControlBar: FC<RegControlBarProps> = ({
  onFastCategorize,
  scheduledTransactionsInRegister,
  accountsFilter,
  filterObject,
  filter,
  transactionListMetrics,
  onSearch,
  showNewTxn,
  showDebtErrorMessage,
  isWindowsDataset,
  onReminderChange,
  downloadFile,
  onApplyFilter,
  acctTxns,
  noNew,
  reminderSetting,
  defaultAccountId,
}) => {
  const { classes, theme } = useStyles();
  const dispatch = useDispatch();
  const { formatAmount, getSharedCurrency } = useQCurrency();
  const { dialogAlert } = useQDialogs();
  const { datasetPreferences } = useQPreferences();
  const isAutoSearchEnabled = datasetPreferences?.transactionRegister?.autoSearch || false;

  const [activeSearch, setActiveSearch] = useState<boolean>(!!filter?.trim());
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showFileImportDialog, setShowFileImportDialog] = useState<boolean>(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [cancellingFilter, setCancellingFilter] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const csvImport = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlag(state, 'csvImport'));
  const transactionFilters = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlag(state, 'transactionFilters'));
  const txnDetailsModal = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlag(state, 'txnDetailsModal'));

  useEffect(() => {
    if (filter !== undefined && filter.trim() !== '') {
      setActiveSearch(true);
    } else {
      setActiveSearch(false);
    }
  }, [filter]);

  const handleSearch = (string: string | null) => {
    if (string !== null) {
      setActiveSearch(string.trim().length > 0);
      onSearch(string.trim());
    }
  };

  const handleDownloadFile = () => {
    if (downloadFile) {
      downloadFile();
    }
  };

  const applyFilter = (filters: FilterObject) => {
    setFilterAnchorEl(null);
    onApplyFilter(filters);
  };

  const cancelFilters = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCancellingFilter(true);
    setTimeout(() => {
      onApplyFilter(new FilterObject());
      setCancellingFilter(false);
    }, 10);
  };


  // If searching
  const { numTxns, sumTxns } = transactionListMetrics;
  const currSymbol = getSharedCurrency(acctTxns);
  const totalText = formatAmount(sumTxns, currSymbol);
  const selText = ` (${numTxns} Transaction${numTxns > 1 ? 's' : ''} Found: ${totalText})`;

  // have to break into the object and count the size of each Set
  const numFilterItems = countFilterItemsForDisplay(filterObject) + (filterObject?.get('payees').size || 0);

  return (
    <>
      {showHelp && searchRef &&
        <SearchHelpBox
          classes={classes}
          anchorEl={searchRef.current}
          onClose={() => setShowHelp(false)}
        />}

      {filterAnchorEl &&
        <QFilterSelect
          onApply={applyFilter}
          initialFilter={filterObject}
          popover
          openPopoverProps={{
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          }}
          anchorEl={filterAnchorEl}
          onClose={() => setFilterAnchorEl(null)}
        />}

      <div className={classes.container}>
        <Typography className={classes.title} variant="h6" noWrap>
          Transaction Activity
        </Typography>

        {(activeSearch || numFilterItems > 0) &&
          <Typography variant="body2" id="reg-header-search-total" className={classes.searchMetrics}>
            {selText}
          </Typography>}


        <div className={classes.searchContainer} ref={(x) => { searchRef.current = x; }}>

          <div className={classes.rightControls}>
            <div
              className={classes.searchBox}
              style={{ borderColor: filterAnchorEl != null ? theme.palette.primary.main : '' }}
            >
              <SearchBox
                onSearch={handleSearch}
                initialValue={filter}
                autoSearch={isAutoSearchEnabled}
                autoFocus
                onHelp={setShowHelp}
              />
            </div>
            {transactionFilters &&
              <div className={classes.filterBox} style={{ borderColor: filterAnchorEl != null ? theme.palette.primary.main : '' }}>
                <QIconButton
                  style={{ color: filterAnchorEl != null ? theme.palette.primary.main : '' }}
                  aria-label="Transaction Filters"
                  onClick={(e) => setFilterAnchorEl(filterAnchorEl ? null : e.currentTarget)}
                  id="reg-add-filter"
                  IconComponent={FilterListIcon}
                  IconProps={{ className: classes.filterIconButton }}
                  tooltip="Filter List"
                  size="small-target"
                />
                <Typography variant="body2" className={classes.filterNum} id="reg-add-filter" onClick={(e) => setFilterAnchorEl(filterAnchorEl ? null : e.currentTarget)}>
                  {numFilterItems}
                </Typography>
                {numFilterItems > 0 &&
                  <ButtonBase
                    className={classes.filterNumClose}
                    onClick={cancelFilters}
                  >
                    {cancellingFilter ? '' : <Typography className={classes.filterNumCloseText} variant="body2"> X </Typography>}
                  </ButtonBase>}
              </div>}
          </div>
          <div className={classes.iconDivider} style={{ minWidth: !(isMobile || isTablet) && csvImport ? 66 : 34 }}>
            {csvImport &&
              <>
                <QIconButton
                  aria-label="Import transactions"
                  id="import-transactions-button"
                  IconComponent={CloudUploadIcon}
                  IconProps={{ className: classes.iconButton }}
                  onClick={(_e) => {
                    setShowFileImportDialog(true);
                  }}
                  tooltip="Import transactions"
                  size="medium-no-padding"
                />
                <FileImportDialog
                  account={accountsFilter.first()}
                  open={Boolean(showFileImportDialog)}
                  onHandleClose={() => setShowFileImportDialog(false)}
                />
              </>}

            {!(isMobile || isTablet) &&
              <QIconButton
                aria-label="Download transactions"
                id="reg-fast-download-csv"
                IconComponent={FileDownload}
                IconProps={{ className: classes.iconButton }}
                onClick={handleDownloadFile}
                tooltip="Download transactions"
                size="small-target"
              />}
          </div>

          {scheduledTransactionsInRegister &&
            <div className={classes.scheduleMenuHolder}>
              {onReminderChange &&
                <ScheduleViewMenu
                  reminderSetting={reminderSetting}
                  onChange={onReminderChange}
                  isWindows={isWindowsDataset}
                />}
            </div>}

          {onFastCategorize &&
            <QIconButton
              aria-label="Categorize transactions"
              id="reg-fast-categorize-all"
              IconComponent={FastCatIcon}
              IconProps={{ className: classes.iconButton }}
              onClick={onFastCategorize}
              tooltip="Automatically categorize uncategorized transactions when a suggestion is available"
              size="small-target"
            />}

          {!noNew &&
            <QTip
              title="Add transaction"
            >
              <Fab
                variant="circular"
                size="small"
                color="primary"
                id="txn-register-add-txn-button"
                className={classes.addTxnButton}
                aria-label="Add"
                onClick={(e) => {
                  e.stopPropagation();
                  if (showDebtErrorMessage) {
                    dialogAlert(
                      'Unsupported Feature',
                      'You may only add, modify or delete loan account transactions on the desktop app',
                      undefined,
                      ['OK'],
                    );
                    return;
                  }
                  if (txnDetailsModal) {
                    dispatch(showTxnDetailsDialog({ defaultAccountId }));
                  } else {
                    showNewTxn();
                  }
                }}
              >
                <AddIcon />
              </Fab>
            </QTip>}
        </div>
      </div>
    </>
  );
};

export default RegControlBar;
