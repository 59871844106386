import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { List } from 'immutable';

// MUI
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MatchIcon from '@mui/icons-material/CompareArrows';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import FileDownload from '@mui/icons-material/GetApp';

// Companion-app-components
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { showReviewed } from 'companion-app-components/flux/preferences/selectors';
import RootState from 'companion-app-components/utils/redux-store/rootState';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

// Data
import * as clipboardActions from 'data/clipboard/actions';
import { mkClipboardItem } from 'data/clipboard/types';
import { getClipboardForKey } from 'data/clipboard/selectors';
import { areMatchable, isUnacceptedScheduledTxn } from 'data/transactions/utils';

import useQData from 'components/QData/useQData';
import useQDialogs from 'components/QDialogs/useQDialogs';
import useQCurrency from 'components/QCurrency/useQCurrency';
import QIconButton from 'components/QIconButton';
import QButton from 'components/QButton';
import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

const canBeCopied = (txn: transactionsTypes.CashFlowTransaction) => !isUnacceptedScheduledTxn(txn);

const fixTxnToPaste = (accountId: string | undefined, txn: transactionsTypes.CashFlowTransaction) =>
  new transactionsTypes.CashFlowTransaction({
    accountId,
    clientId: uuidv4(),
    postedOn: txn.postedOn,
    payee: txn.payee,
    memo: txn.memo,
    coa: txn.coa,
    amount: txn.amount,
    split: transactionsTypes.mkSplitCopy(txn.split),
    tags: txn.tags,
  });

interface RegMEditBarProps {
  selectedTxns: List<transactionsTypes.CashFlowTransaction>;
  onEdit: () => void;
  onDelete: () => void;
  onDownload: () => void;
  onMatch: () => void;
  onReviewed: () => void;
  onNotReviewed: () => void;
  accountIds: List<string>;
}

const RegMEditBar: React.FC<RegMEditBarProps> = ({
  selectedTxns,
  onEdit,
  onDelete,
  onMatch,
  onReviewed,
  onNotReviewed,
  onDownload,
  accountIds,
}) => {
  const isMultiAccount = accountIds?.size > 1;
  const accountId = accountIds?.first();

  // DISPATCHERS ========================================================================
  const dispatch = useDispatch();
  const copyToClipboard = (txnList: List<transactionsTypes.CashFlowTransaction>) =>
    dispatch(
      clipboardActions.copyToClipboard(
        mkClipboardItem({
          data: txnList,
          key: 'bankTransactions',
        }),
      ),
    );
  const clearClipboard = () => dispatch(clipboardActions.clearClipboardKey('bankTransactions'));

  // SELECTORS ========================================================================
  const { classes } = useStyles();
  const clipboardTxns = useSelector((state: RootState) => getClipboardForKey(state, 'bankTransactions')?.first()?.data);
  const copyPasteEnabled = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state).get('copyPasteEnabled'));
  const showReview = useSelector(showReviewed);
  const { getSharedCurrency, formatAmount } = useQCurrency();
  const { qDataSaveTransactions } = useQData();
  const { dialogAlert } = useQDialogs();

  const getTxnInfo = () => {
    let total = 0;
    let hasRecurring = false;
    selectedTxns?.forEach((item) => {
      total += Number(item.amount);
      hasRecurring = hasRecurring || isUnacceptedScheduledTxn(item);
    });
    return { total, hasRecurring };
  };

  const matchAvailable = () => {
    if (selectedTxns?.size !== 2) {
      return false;
    }
    const txns = selectedTxns.toArray();

    return areMatchable(txns[0], txns[1]);
  };

  const onCopy = () => {
    copyToClipboard(selectedTxns);
  };

  const cancelCopy = () => {
    clearClipboard(); // TODO: should this be 'bankTransactions'
  };

  const onPaste = () => {
    if (!isMultiAccount && clipboardTxns?.size > 0) {
      let newTxnList = clipboardTxns.filter((txn) => canBeCopied(txn));
      newTxnList = clipboardTxns.map((txn) => fixTxnToPaste(accountId, txn)).toList();

      dialogAlert(
        'Paste transactions?',
        `Are you sure you want to paste ${newTxnList?.size} transaction(s) into this account?`,
        (btnObj) => {
          if (btnObj.btnPressed === 'Yes') {
            qDataSaveTransactions(newTxnList);
            clearClipboard();
          }
        },
        ['No', 'Yes'],
      );
    }
  };

  const txnsCopied = clipboardTxns?.size > 0;
  const numSelected = selectedTxns?.size;
  const showMatch = !txnsCopied && matchAvailable();
  const txnInfo = getTxnInfo();

  const currSymbol = getSharedCurrency([selectedTxns]);
  const totalText = formatAmount(txnInfo.total, currSymbol);
  const selText = txnsCopied
    ? ` You copied ${clipboardTxns?.size} Transaction${clipboardTxns?.size > 1 ? 's' : ''}  to the clipboard (${totalText})`
    : ` ${numSelected} Transaction${numSelected > 1 ? 's' : ''} Selected  (${totalText})`;

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        {showMatch && (
          <QIconButton
            aria-label="Match transactions"
            id="bulk-edit-ui-match"
            IconComponent={MatchIcon}
            onClick={onMatch}
            tooltip={'Match the downloaded transaction to the manual transaction'}
            size="small-target"
          />
        )}

        {!txnInfo.hasRecurring && (
          <>
            {(!txnsCopied || !isMultiAccount) && copyPasteEnabled && (
              <QIconButton
                aria-label={`${txnsCopied ? 'Paste copied' : 'Copy selected'} transactions`}
                id="bulk-edit-ui-copy"
                IconComponent={txnsCopied ? AssignmentReturnedOutlinedIcon : FileCopyOutlinedIcon}
                onClick={txnsCopied ? onPaste : onCopy}
                tooltip={`${txnsCopied ? 'Paste selected transactions from clipboard' : 'Copy selected transactions to clipboard'}`}
                size="small-target"
              />
            )}

            {!txnsCopied && (
              <QIconButton
                aria-label="Edit selected transactions"
                id="bulk-edit-ui-edit"
                IconComponent={EditIcon}
                onClick={onEdit}
                tooltip="Bulk edit all selected transactions at once"
                size="small-target"
              />
            )}
          </>
        )}

        {!txnsCopied && (
          <QIconButton
            aria-label="Delete selected transactions"
            id="bulk-edit-ui-delete"
            IconComponent={DeleteIcon}
            onClick={onDelete}
            tooltip="Delete all selected transactions"
            size="small-target"
          />
        )}

        {!txnsCopied && !txnInfo.hasRecurring && showReview && (
          <>
            <QIconButton
              aria-label="Mark selected transactions as reviewed"
              id="bulk-edit-ui-mark-as-reviewed"
              IconComponent={RadioButtonUncheckedIcon}
              IconProps={{ className: classNames(classes.reviewed, 'reviewed') }}
              onClick={onReviewed}
              tooltip="Mark all selected transactions reviewed"
              size="small-target"
            />
            <QIconButton
              aria-label="Mark all selected transactions as not reviewed"
              id="bulk-edit-ui-mark-as-not-reviewed"
              IconComponent={CircleIcon}
              IconProps={{ className: classNames(classes.reviewed, 'notReviewed') }}
              onClick={onNotReviewed}
              tooltip="Mark all selected transactions as not reviewed"
              size="small-target"
            />
          </>
        )}

        {!txnsCopied && (
          <QIconButton
            aria-label="Download a CSV file with all selected transactions"
            id="bulk-edit-ui-mark-as-reviewed"
            IconComponent={FileDownload}
            onClick={onDownload}
            tooltip="Download a CSV file with all selected transactions"
            size="small-target"
          />
        )}
      </div>
      <Typography variant="subtitle1" className={classes.text} id="reg-header-selected-total">
        {selText}
      </Typography>

      {txnsCopied && (
        <QButton id="copy-reg-cancel" variant="outlined" onClick={cancelCopy} className={classes.cancelBtn}>
          Cancel
        </QButton>
      )}
    </div>
  );
};

export default RegMEditBar;
