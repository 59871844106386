import React, { FC, JSX, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import type { List as ImList } from 'immutable';

import { makeStyles } from 'tss-react/mui';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import DoneIcon from '@mui/icons-material/DoneRounded';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { CashFlowTransaction } from 'companion-app-components/flux/transactions/transactionsTypes';

import { UseStylesType } from 'components/AccountCard/types';
import QTip from 'components/QuickenControls/QTip';
import { formatNumber } from 'components/QuickenControls/AmountField';
import { getFieldString } from 'data/transactions/searchFilter';
import styles from './styles';

const DATE_FORMAT = 'MM/dd/yyyy';

const useStyles = makeStyles()(styles as Record<string, any>);

const commonStyles = (flex, textAlign, minWidth) => ({ flex, textAlign, minWidth });

type TextAlign = 'left' | 'right' | 'center';

type ColumnType = {
  label: string;
  flex: number;
  field: string;
  align: TextAlign
  minWidth?: number;
  padding?: number;
};

interface TransactionSelectListProps {
  transactions: ImList<unknown>,
  accountType: string,
  title: string,
  showTotals: boolean,
  onClick: (txn: CashFlowTransaction) => void,
  onKeyDown: () => void,
  id: string,
  currencyString?: string,
  className: string,
}

const TransactionSelectList: FC<TransactionSelectListProps> = (props) => {
  const { classes, theme }: UseStylesType = useStyles();
  
  let cols: ColumnType[] = [
    { label: 'Clr', flex: 1, field: 'state', align: 'center' },
    { label: 'Date', flex: 2, field: 'postedOn', align: 'left', minWidth: 85 },
    { label: 'Posted', flex: 2, field: 'posting', align: 'left', minWidth: 85 },
  ];
  if (props.accountType !== 'CREDIT') {
    cols.push({ label: 'Chk#', flex: 2, field: 'check', align: 'left' });
  }
  cols = cols.concat([
    { label: 'Payee', flex: 3, field: 'payee', align: 'left' },
    { label: 'Amount', flex: 2, field: 'amount', align: 'right', padding: 2 },
  ]);

  const [totalAmount, setTotalAmount] = useState<number>(
    props.transactions.reduce((v: number, txn: CashFlowTransaction) => 
      v + (txn.state === 'CLEARED' ? Number(txn.amount) : 0), 0.0),
  );

  const getStringForField = (txn, field) => {
    switch (field) {
      case 'posting':
        return txn.cpData ? DateTime.fromISO(txn.cpData.postedOn).toFormat(DATE_FORMAT) : '';
      case 'postedOn':
        return DateTime.fromISO(txn.postedOn).toFormat(DATE_FORMAT);
      case 'state':
        return txn.state === 'CLEARED' ? <DoneIcon sx={{ color: 'green1' }} /> : '';
      default:
        return getFieldString(field, txn.toJS());
    }
  };

  const transactionClick = (txn: CashFlowTransaction) => {
    if (props.onClick) {
      props.onClick(txn);
    }
  };

  const renderTxRow = (
    title: string,
    txn: CashFlowTransaction,
    columns: ColumnType[],
  ) => {
    const ret: JSX.Element[] = [];
    columns.forEach((col) => {
      ret.push(
        <div
          key={`${title}:${txn.id}:${col.label}`}
          className={classes.divTxnRow}
          style={commonStyles(col.flex, col.align, col.minWidth)}
        >
          <QTip
            wrapOnly
            title={String(getStringForField(txn, col.field))}
          >
            <Typography
              variant="body2"
              className={classNames(classes.txField, txn.state === 'CLEARED' ? 'cleared' : '')}
            >
              {getStringForField(txn, col.field)}
            </Typography>
          </QTip>
        </div>,
      );
    });
    return ret;
  };

  const renderTransactions = (
    title: string,
    transactions: ImList<unknown>,
    columns: ColumnType[],
  ) => {
    const ret: JSX.Element[] = [];
    transactions.reverse().forEach((txn: CashFlowTransaction) => {
      ret.push(
        <MenuItem
          key={`txrowrecon:${title}-${txn.id}`}
          classes={{ selected: classes.selectedItem, root: classes.listRoot }}
          onClick={() => transactionClick(txn)}
          divider
        >
          <div
            className={classes.txRow}
          >
            {renderTxRow(title, txn, columns)}
          </div>
          <Divider />
        </MenuItem>,
      );
    });
    return (
      <MenuList
        classes={{ root: classes.listRoot }}
        dense
        disablePadding
        onKeyDown={props.onKeyDown}
      >
        {ret}
      </MenuList>
    );
  };

  const prevTransactions = usePrevious(props.transactions);

  useEffect(() => {
    if (props.transactions !== prevTransactions) {
      setTotalAmount(props.transactions.reduce((v: number, txn: CashFlowTransaction) => 
        v + (txn.state === 'CLEARED' ? Number(txn.amount) : 0), 0));
    }
  }, [props.transactions, prevTransactions]);

  return (
    <List
      className={props.className}
      classes={{ root: classes.listRoot }}
      dense
      disablePadding
      subheader={<div />}
      onKeyDown={props.onKeyDown}
      id={props.id}
    >
      <ListSubheader disableGutters sx={{ background: theme.palette.white }}>
        <div className={classes.listRoot}>
          <div className={classes.titleWrapper}>
            <Typography variant="subtitle2">
              {props.title}
            </Typography>
          </div>
        </div>

        <div className={classes.listRoot}>
          <div className={classes.colsWrapper}>
            {cols.map((col) => (
              <div
                key={`${props.title}:header:${col.label}`}
                style={{
                  ...commonStyles(col.flex, col.align, col.minWidth),
                  paddingRight: col.padding,
                  background: theme.palette.silverGrey,
                }}
              >
                <Typography variant="subtitle2">
                  {col.label}
                </Typography>
              </div>))}
          </div>
        </div>
      </ListSubheader>
      {renderTransactions(props.title, props.transactions, cols)}
      {props.showTotals && (
        <ListItem classes={{ root: classes.listRoot }}>
          <div className={classNames(classes.colsWrapper, classes.divListItem)}>
            {cols.map((col) => (
              <div
                key={`${props.title}:footer:${col.label}`}
                className={classes.divListItemColumn}
                style={commonStyles(
                  col.field === 'amount' || col.field === 'state' ? 4 : 0,
                  col.field === 'state' ? 'left' : col.align,
                  col.field === 'amount' || col.field === 'state' ? col.minWidth : null,
                )}
              >
                <Typography variant="subtitle2" className={classes.listFooter}>
                  {col.field === 'state' ? `Cleared ${props.title}` : ''}
                  {col.field === 'amount' ? formatNumber(totalAmount, props.currencyString, '0,00.00') : ''}
                </Typography>
              </div>))}
          </div>
        </ListItem>
      )}
    </List>
  );
};

export default TransactionSelectList;
