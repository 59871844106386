import React, { ChangeEvent, FC, useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Paper from '@mui/material/Paper/Paper';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

import { Account } from 'companion-app-components/flux/accounts/accountsTypes';

import DateField from 'components/QuickenControls/DateField';
import AmountField, { formatNumber } from 'components/QuickenControls/AmountField';

type ReconcileSetupStateType = {
  useOnline: boolean;
  statementEndBalance: number | string;
  statementEndDate?: string;
};

interface ReconcileSetupProps {
  onClose: () => void;
  onSave: (state: ReconcileSetupStateType) => void;
  isOnline: boolean;
  account: Account;
  lastReconciledBalance: number;
  classes: Record<string, any>;
}

const ReconcileSetup: FC<ReconcileSetupProps> = ({
  onClose,
  onSave,
  classes,
  isOnline,
  account,
  lastReconciledBalance,
}) => {
  const [state, setState] = useState<ReconcileSetupStateType>({
    useOnline: isOnline,
    statementEndBalance: isOnline ? Number(account.onlineBalance) : '',
    statementEndDate: isOnline ? account.onlineBalanceAt : DateTime.now().toFormat('yyyy-MM-dd'),
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    switch (field) {
      case 'postedOn':
        if (e) {
          setState((prevState) => ({
            ...prevState,
            statementEndDate: e.target.value,
          }));
        }
        break;

      case 'useOnline':
        setState((prevState) => ({
          ...prevState,
          useOnline: !state.useOnline,
        }));
        break;

      case 'amount':
      default:
        if (e) {
          setState((prevState) => ({
            ...prevState,
            [field]: e.target.value,
          }));
        }
        break;
    }
  };

  const saveFn = () => {
    onSave(state);
  };

  const closeAndExit = () => {
    onClose();
  };

  return (
    <Dialog
      classes={{ paperWidthXs: classes.paperWidthSm }}
      open
      maxWidth="xs"
      onClose={closeAndExit}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogTitle>Reconcile Transactions</DialogTitle>
      <div>
        {isOnline &&
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.labelBold }}
              control={
                <Switch
                  checked={state.useOnline}
                  onChange={(e) => handleChange(e, 'useOnline')}
                  value="useOnline"
                  color="primary"
                />
              }
              label={`Use Online Balance: ${formatNumber(account.onlineBalance, account.currency, '0,0.00')}`}
            />
          </FormGroup>}

        <Divider />
        <FormGroup classes={{ root: classes.formGroup }}>
          {!state.useOnline &&
            <FormControlLabel
              classes={{ root: classNames(classes.formControlLabel, 'bottomSpace') }}
              control={
                <div className={classes.divFormControlLabel}>
                  <AmountField
                    autoFocus
                    amountType="amount"
                    editable={false}
                    value={String(lastReconciledBalance)}
                    currencySymbol={account.currency}
                    className={state.useOnline ? classes.inputFieldsBold : classes.inputFields}
                    maxChars={16}  // support balances under 10 billion (with decimal and 2 decimal points)
                  >
                  </AmountField>
                </div>
              }
              label="Previous Reconciled Balance:"
              labelPlacement="start"
            />}
          <FormControlLabel
            classes={{ root: classNames(classes.formControlLabel, 'bottomSpace') }}
            control={
              <div className={classes.divFormControlLabel}>
                <AmountField
                  autoFocus
                  amountType="amount"
                  onChange={(e) => handleChange(e, 'statementEndBalance')}
                  editable={!state.useOnline}
                  value={String(state.statementEndBalance)}
                  currencySymbol={account.currency}
                  className={state.useOnline ? classes.inputFieldsBold : classes.inputFields}
                >
                </AmountField>
              </div>
            }
            label="Statement Ending Balance:"
            labelPlacement="start"
          />
          <FormControlLabel
            classes={{ root: classes.formControlLabel }}
            control={
              <div className={classes.divFormControlLabel}>
                <DateField
                  fieldId="postedOn"
                  editable={!state.useOnline}
                  value={state.statementEndDate}
                  onChange={(e) => handleChange(e, 'postedOn')}
                  todaysDateIfWrong
                  customStyle={{ marginLeft: 10, fontWeight: state.useOnline ? 800 : null, fontSize: '16px' }}
                />
              </div>
            }
            label="Statement Ending Date:"
            labelPlacement="start"
          />
        </FormGroup>
      </div>

      <Divider />
      <Typography variant="body2">
        Note: If there are any older unreconciled transactions on the desktop client that have not been synced,
        they are not considered for reconciliation
      </Typography>
      <Divider />
      <Paper elevation={0} className={classes.buttonHolder}>
        <div className={classes.divFlexFull} />
        <Button
          className={classes.buttons}
          onClick={closeAndExit}
          color="primary"
          id="bulk-edit-cancel"
        >
          Cancel
        </Button>
        <Button
          className={classes.buttons}
          onClick={saveFn}
          name="bulk-edit-last-tab"
          color="primary"
          id="bulk-edit-save"
        >
          Next
        </Button>
      </Paper>
    </Dialog>
  );
};

export default ReconcileSetup;
