import React, { FC, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';

import RootState from 'companion-app-components/utils/redux-store/rootState';
import { tracker } from 'companion-app-components/utils/core';
import { getAccountsById } from 'companion-app-components/flux/accounts/accountsSelectors';
import { isActiveConnectedAccountFromId } from 'companion-app-components/flux/accounts/accountsUtils';

import { getBalancesByAccountId } from 'data/accountBalances/selectors';
import { getReconcileInfoForAccount } from 'data/reconcileList/selectors';
import useQDialogs from 'components/QDialogs/useQDialogs';

import { styles } from './styles';
import ReconcileSetup from './ReconcileSetup';
import ReconcileWorkspace from './ReconcileWorkspace';

const useStyles = makeStyles()(styles as Record<string, any>);

interface ReconcilePanelProps {
  accountId: string;
  onClose: () => void;
}

/**
 * Presents the form to bulk edit a group of transactions
 */
const ReconcilePanel: FC<ReconcilePanelProps> = (props) => {
  const { dialogAlert } = useQDialogs();
  const { classes } = useStyles();

  const [state, setState] = useState({
    statementEndDate: null,
    statementEndBalance: null,
    showSetup: true,
    useOnline: false,
  });

  const accountBalances = useSelector((rootState: RootState) => getBalancesByAccountId(rootState).get(props.accountId));
  const accountRecord = useSelector((rootState: RootState) => getAccountsById(rootState).get(props.accountId));
  const reconcileInfo = useSelector((rootState: RootState) => getReconcileInfoForAccount(rootState, {
    accountId: props.accountId,
    statementDate: isActiveConnectedAccountFromId(props.accountId, rootState) ? null : DateTime.now().toFormat('yyyy-MM-dd'),
    statementEndingBalance: 0,
  }));

  const closeAndExit = (status?: string) => {
    if (status && status !== 'canceled') {
      tracker.track(tracker.events.reconcileAnAccount, {
        method: state.useOnline ? 'Online Balance' : 'Paper Statement',
        status,
      });
    }
    props.onClose();
  };

  const onSave = (data) => {
    tracker.track(tracker.events.reconcileAnAccount, {
      method: data.useOnline ? 'Online Balance' : 'Paper Statement',
      status: 'Started',
    });

    setState({
      useOnline: data.useOnline,
      showSetup: false,
      statementEndDate: data.statementEndDate,
      statementEndBalance: data.statementEndBalance,
    });
  };

  useEffectOnce(() => {
    if (!reconcileInfo) {
      closeAndExit();
    }
  });

  if (!reconcileInfo) {
    dialogAlert(
      'There are no transactions to reconcile',
      '',
      null,
      ['Got It'],
      'info',
    );
    return null;
  }

  return (
    <>
      {state.showSetup &&
        <ReconcileSetup
          accountBalances={accountBalances}
          // @ts-expect-error - FIXME: Type 'undefined' is not assignable to type 'Account'
          account={accountRecord}
          // @ts-expect-error TS(2554) - FIXME: Expected 2 arguments, but got 1
          isOnline={isActiveConnectedAccountFromId(props.accountId)}
          lastReconciledBalance={reconcileInfo.get('registerReconciledBalance')}
          onSave={onSave}
          onClose={closeAndExit}
          classes={classes}
        />}
      {!state.showSetup &&
        <ReconcileWorkspace
          // @ts-expect-error - FIXME: Type 'undefined' is not assignable to type 'Account'
          account={accountRecord}
          statementEndDate={state.statementEndDate}
          statementEndBalance={Number(state.statementEndBalance)}
          onClose={closeAndExit}
          classes={classes}
        />}
    </>
  );
};

export default memo(ReconcilePanel);
