export const amountFieldStyles = ({ palette }) => ({
  inputField: {
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  red: {
    color: `${palette.number.negative} !important`,
    whiteSpace: 'nowrap',
  },
  green: {
    color: `${palette.number.positive} !important`,
    whiteSpace: 'nowrap',
  },
  black: {
    color: `${palette.black} !important`,
    whiteSpace: 'nowrap',
  },
  white: {
    color: palette.white,
    whiteSpace: 'nowrap',
  },
  primary: {
    color: palette.primary.main,
    whiteSpace: 'nowrap',
  },
  secondary: {
    color: palette.secondary.main,
    whiteSpace: 'nowrap',
  },
  error: {
    color: palette.primary.error,
    whiteSpace: 'nowrap',
  },
  amountadornment: {
    marginLeft: 0,
    marginRight: 4,
  },
});

export const amountSpanStyle = ({ palette, applyOpacityToHex }) => {
  const root = {
    color: `${palette.black} !important`,
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 3,
    verticalAlign: 'middle',
    border: '1px solid transparent',
  };
  return ({
    root,
    normal: {
      ...root,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: applyOpacityToHex(palette.black, 0.1),
      },
    },
    focused: {
      letterSpacing: '0.4px',
      ...root,
      border: `1px solid ${applyOpacityToHex(palette.primary.main, 0.6)}`,
      backgroundColor: palette.white,
    },
    editSpan: {
      outlineWidth: 0,
      outlineStyle: 'none',
      minWidth: 4,
      padding: 3,
      paddingRight: 5,
    },
    underlineFocused: {
      letterSpacing: '0.4px',
      ...root,
      border: `1px solid ${applyOpacityToHex(palette.primary.main, 0.6)}`,
      backgroundColor: palette.white,
      borderRadius: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  });
};
