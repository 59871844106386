import React from 'react';

import { tracker } from 'companion-app-components/utils/core';

import { IconButtonOwnProps } from '@mui/material';

import useQPreferences from 'components/QPreferences/useQPreferences';
import QMenu from 'components/QMenu';
import { visibleBalances } from 'data/accountBalances/types';

interface BalanceSelectMenuProps {
  menuIconButtonSize?: IconButtonOwnProps['size'];
  classes: Record<string, any>;
}

const BalanceSelectMenu: React.FC<BalanceSelectMenuProps> = ({ menuIconButtonSize, classes }) => {
  const { datasetPreferences, setDatasetPreference } = useQPreferences();

  const handleAccountBarBalanceMenu = (balType: string, groupId: string) => {
    if (groupId === 'balDisplay') {
      setDatasetPreference({ accountBarBalanceType: balType });

      // Tracking what got changed
      tracker.track(tracker.events.accountBalanceChange, {
        type: 'accountList',
        changedTo: balType,
      });
    } else if (groupId === 'showCents') {
      setDatasetPreference({ accountBarShowCents: !datasetPreferences.accountBarShowCents });
    }
  };

  const { accountBarBalanceType, accountBarShowCents } = datasetPreferences;

  const menuOptions = visibleBalances.map((balObj) => ({ label: balObj.label, value: balObj.value }));

  menuOptions[menuOptions.length - 1].divider = true;

  return (
    <div className={classes.balanceMenu}>
      <QMenu
        name="balmenu"
        title="Select balance type to view"
        options={[
          {
            label: 'Balance Display',
            groupId: 'balDisplay',
            isGroupList: true,
            selected: accountBarBalanceType,
            groupList: menuOptions,
          },
          {
            label: null,
            groupId: 'showCents',
            isGroupList: true,
            selected: accountBarShowCents ? 'showCents' : null,
            groupList: [{ label: 'Show Cents', value: 'showCents' }],
          },
        ]}
        onChange={handleAccountBarBalanceMenu}
        menuIconButtonSize={menuIconButtonSize || ('smallNoPadding' as IconButtonOwnProps['size'])}
      />
    </div>
  );
};

export default BalanceSelectMenu;
