import numeral from 'numeral';
import getSymbolFromCurrency from 'currency-symbol-map';

import { noNaN } from 'utils/utils';

export const pruneString = (value) => {
  let str = '';
  const legalChars = '0123456789.';
  for (let i = 0; i < value.length; i += 1) {
    // only keep legal characters (only numbers and decimals)
    str += legalChars.indexOf(value[i]) < 0 ? '' : value[i];
  }
  return str;
};

export const formatNumber = (value, fmtString = '0,0.00') => `${numeral(Math.abs(Number(value))).format(fmtString)}`;

export const chopValue = (value, decimals = 2) => {
  const periodIndex = value.indexOf('.');
  if (periodIndex > -1 && value.length > periodIndex + decimals) {
    return value.slice(0, periodIndex + decimals);
  }
  return value;
};

export const signFromValue = (value) => {
  switch (Math.sign(value)) {
    case 1:
      return '+';
    case -1:
      return '-';
    default:
      return '';
  }
};

export const operatorFromSign = (operator, negator) => {
  switch (operator) {
    case '+':
      return 1;
    case '-':
      return -1;
    default:
      return negator;
  }
};

export const getCaretPosition = (el) => {
  let caretPos = 0;
  if (typeof window.getSelection !== 'undefined' && el) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const selected = range.toString().length;
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(el);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretPos = preCaretRange.toString().length - selected;
    }
  }
  return caretPos;
};

export const setCaretPosition = (el, offset) => {
  if (typeof window.getSelection !== 'undefined') {
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(el.firstChild, offset);
    range.collapse(true);
    sel?.removeAllRanges();
    sel?.addRange(range);
  }
};

export const formatNumberAmountField = (val, currencySymbol = 'USD', fmtString = '0,0', showSign = ShowSignEnum.NEGATIVE_ONLY) => {
  return internalFormatNumber(val, getSymbolFromCurrency(currencySymbol || 'N/A') || '', fmtString, showSign);
};

export enum ShowSignEnum {
  NEGATIVE_ONLY = 'negative-only',
  POSITIVE_ONLY = 'positive-only',
  NEVER = 'never',
  ALWAYS = 'always',
}

export enum ShowColorEnum {
  MANUAL = 'manual',
  NEGATIVE_ONLY = 'negative-only',
  POSITIVE_ONLY = 'positive-only',
  ALWAYS = 'always',
};

export const internalFormatNumber = (val, currencyString, fmtString, showSign = ShowSignEnum.NEGATIVE_ONLY) => {
  const value = noNaN(round(Number(val)));
  let sign = '';
  switch (showSign) {
    case ShowSignEnum.POSITIVE_ONLY:
      sign = Number(value) > 0 ? '+' : '';
      break;
    case ShowSignEnum.NEVER:
      sign = '';
      break;
    case ShowSignEnum.ALWAYS:
      sign = Number(value) > 0 ? '+' : '-';
      if (Number(value) === 0) sign = '';
      break;
    default:
    case ShowSignEnum.NEGATIVE_ONLY:
      sign = Number(value) < 0 ? '-' : '';
  }
  return `${sign}${currencyString}${numeral(Math.abs(Number(value))).format(fmtString)}`;
};

export const round = (value, decimals = 2) => {
  const roundedValue = Math.round(Number(`${value}e${decimals}`));
  return Number(`${roundedValue}e-${decimals}`);
};
