const styles = ({ defaults, palette, spacing, components }) => ({
  header: {
    minHeight: defaults.headers.headerHeight,
    borderBottom: `1px solid ${palette.gray5}`,  // in lieu of the shadow, use a border
    backgroundColor: palette.white,
    zIndex: 2,
  },
  paperContainer: {
    marginBottom: spacing(3),
    padding: spacing(2),
    minHeight: 500,
    overflowY: 'auto',
  },
  textEllipsis: {
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap',
  },
  headerControls: {
    textTransform: 'none',
    fontSize: 16,
  },
  datasetSwitchingContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  datasetSwitchingProgress: {
    marginTop: 30,
  },
  disableSection: {
    pointerEvents: 'none',
  },
  alert: {
    background: palette.hint,
    color: palette.grey.level7,
    fontSize: 16,
    padding: '2px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  sharedTableAlert: {
    padding: '2px 8px',
    margin: '0 8px 8px 8px',
  },
  anchorStyle: {
    color: `${components.navigation.headerColor} !important`,
    textDecoration: 'none',
  },
});

export default styles;
