export const styles = (theme) => ({

  dialogPaper: {
    overflowY: 'visible',
  },
  preferencesText: {
    fontSize: theme.components.register.fontSize.default,
  },
  buttonArea: {
    padding: 10,
    paddingTop: 0,
  },
  link: {
    verticalAlign: 'inherit',
    marginLeft: 4,
    textDecoration: 'underline',
    color: 'blue',
    '&:focus': {
      background: '#dedede',
    },
    width: 'inherit',
    textAlign: 'left',
    justifyContent: 'left',
    textTransform: 'initial',
  },
});
