import React, { FC, useState, useRef, useEffect, useCallback, 
  CSSProperties, KeyboardEventHandler, ChangeEvent, FocusEventHandler } from 'react';
import numeral from 'numeral';
import classNames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import { withStyles } from 'tss-react/mui';

import TextField, { TextFieldVariants } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import QTypography from 'components/MUIWrappers/QTypography';
import QTip from 'components/QuickenControls/QTip';
import { noNaN, exists, isBrowser } from 'utils/utils';
import { quickenAmountToString } from 'data/transactions/utils';

import { ShowSignEnum, ShowColorEnum, internalFormatNumber } from './utils';
import { amountFieldStyles } from './styles';

interface AmountFieldProps {
  editable: boolean;
  className: string; // applied to root of textField, use 'classes.inputField' if you want to style the inner input
  style: CSSProperties;
  InputProps: Record<string, any>;
  onKeyDown: (event: KeyboardEventHandler<HTMLDivElement>) => void;
  zeroIsBlank: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type?: string) => void;
  amountType: string;
  value: number;
  updateValue: string;
  useAbsoluteValue: boolean;
  onBlur: (event: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>, type: string, enter: boolean) => void;
  onFocus: (event: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>) => void;
  autoFocus: boolean;
  inputRef: (element) => void;
  currencySymbol: string;
  currencyGap: boolean;
  omitCents: boolean;
  proposedSign: number;
  lockInitialProposedSign: boolean;
  showSign: ShowSignEnum; // ShowSignEnum
  showColor: ShowColorEnum; // ShowColorEnum
  color: string;
  disableUnderline: boolean;
  submitOnBlur: boolean;
  onSubmit: () => void;
  showAmountAdornment: boolean;
  compact: boolean;
  hideCurrencyString: boolean;
  variant: string;
  textFieldVariant: TextFieldVariants;
  marginProp?: 'dense' | 'normal' | 'none';
  label: string;
  maxChars: number;
  classes: Record<string, any>;
}

const AmountField: FC<AmountFieldProps> = ({
  editable,
  className,
  style,
  InputProps,
  onKeyDown,
  zeroIsBlank,
  onChange,
  amountType = 'amount',
  value,
  updateValue,
  useAbsoluteValue,
  onBlur,
  onFocus,
  autoFocus,
  inputRef,
  currencySymbol = 'USD',
  currencyGap,
  omitCents,
  proposedSign,
  lockInitialProposedSign,
  showSign,
  showColor = ShowColorEnum.MANUAL,
  color,
  disableUnderline,
  submitOnBlur,
  onSubmit,
  showAmountAdornment,
  compact,
  hideCurrencyString,
  variant,
  textFieldVariant = 'standard',
  marginProp,
  label,
  maxChars,
  classes,
  ...others
}) => {

  const textInput = useRef(null);
  const initialValueRef = useRef(value);
  const proposedSignRef = useRef(proposedSign);
  const selection = useRef({ start: false, end: false });

  const validateField = useCallback((valuePassed, noStrip = false) => {
    // only allow 2 decimals in amount field, and only 1 decimal point
    // need to give each field their own handler, and then have a default handler
    let str = '';
    let newVal = noStrip ? valuePassed : valuePassed.replace('$', '').trim();
    const legalChars = amountType === 'amount' || amountType === 'balance' ? '0123456789.-+' : '0123456789.';
    // strip out illegal characters (only numbers and decimals)
    for (let i = 0; i < newVal.length; i += 1) {
      str += legalChars.indexOf(newVal[i]) < 0 ? '' : newVal[i];
    }
    newVal = str;
    // This code will ensure there are only 2 digits to the right of the decimal
    if (str) {
      if (str.indexOf('.') >= 0 && str.length > 1) {
        if (str.length - str.indexOf('.') > 3 || valuePassed.indexOf('.') !== valuePassed.lastIndexOf('.')) {
          newVal = parseFloat(Number(str).toFixed(2)).toString();
        }
      }
    }
    return newVal;
  }, [amountType]);

  const valueFromProps = useCallback(() => {
    let val = numeral(value || updateValue || '');
    if (editable && val === 0) {
      val = '';
    } else {
      val = val.format(`${showSign === ShowSignEnum.ALWAYS ? '+-' : ''}0,00.00`);
      val = validateField(val, true);
    }
    return val;
  }, [value, updateValue, editable, showSign, validateField]);
  
  const [valueState, setValueState] = useState(() => valueFromProps());

  const [currencyString, setCurrencyString] = useState(() => !hideCurrencyString ? (getSymbolFromCurrency(currencySymbol) || '').trim() : '');

  useEffect(() => {
    // Handle value updates
    if (!Number.isNaN(Number(value)) || !Number.isNaN(Number(updateValue))) {
      let newVal = value || updateValue || '';
      newVal = validateField(String(newVal), true);
      if (Number(newVal) !== Number(value)) {
        setValueState(newVal);
      }
    }

    // Handle proposedSign updates with lockInitialProposedSign logic
    if (
      proposedSign !== proposedSignRef.current &&
      (!lockInitialProposedSign || typeof proposedSignRef.current === 'undefined')
    ) {
      proposedSignRef.current = proposedSign;
    }

    // Handle currency symbol updates
    if (currencySymbol !== currencyString) {
      setCurrencyString((getSymbolFromCurrency(currencySymbol) || '').trim());
    }

    // Handle editable updates
    if (editable) {
      setValueState(valueFromProps());
    }
  }, [value, updateValue, currencySymbol, editable, proposedSign, lockInitialProposedSign, currencyString, validateField, valueFromProps]);

  useEffect(() => {
    if (textInput.current) {
      const { selectionStart, selectionEnd } = textInput.current;
      const update =
        (selection.current.start !== false && selection.current.start !== selectionStart) ||
        (selection.current.end !== false && selection.current.end !== selectionEnd);

      if (update && !isBrowser('safari')) {
        textInput.current.setSelectionRange(selection.current.start, selection.current.end);
      }
    }
  });

  const tooltip = () => {
    if (compact) {
      return internalFormatNumber(value, currencyString, '0,0.00', showSign);
    }
    return '';
  };

  const Wrap = compact ? QTip : React.Fragment;
  const wrapProps = compact ? { title: tooltip() } : {};

  let colorClass;
  switch (showColor) {
    case ShowColorEnum.POSITIVE_ONLY:
      colorClass = value > 0 ? classes.green : undefined;
      break;
    case ShowColorEnum.NEGATIVE_ONLY:
      colorClass = value < 0 ? classes.red : undefined;
      break;
    case ShowColorEnum.ALWAYS:
      if (value < 0) {
        colorClass = classes.red;
      } else if (value > 0) {
        colorClass = classes.green;
      }
      break;
    case ShowColorEnum.MANUAL:
      colorClass = classes[color];
      break;
    default:
      assert(false, `unknown color mode '${showColor}'`);
  }

  const otherProps = {
    style,
    InputProps,
    onChange,
    onBlur,
    ...others,
  };

  const renderStaticValue = () => {
    if (!exists(value) || (zeroIsBlank && noNaN(value) === 0)) {
      return <span>{'\u00A0'}</span>; // Danger, u00A0 may be removed from browser support.
    }
    return internalFormatNumber(
      value,
      `${currencyString}${(currencyString && currencyGap) ? ' ' : ''}`,
      `0,0${omitCents ? '' : '.00'}${compact ? 'a' : ''}`,
      showSign,
    );
  };

  const handleChange = (e) => {
    if (textInput.current) {
      selection.current = {
        start: textInput.current.selectionStart,
        end: textInput.current.selectionEnd,
      };
    }
    let newVal = validateField(e.target.value);
    setValueState(newVal);
    if (useAbsoluteValue) {
      // make negative
      newVal = `-${newVal}`;
    }
    e.target.value = newVal;
    if (onChange) {
      onChange(e, amountType);
    }
  };

  const handleFocus = (e) => {
    const numCharsToStrip = (currencyString ? currencyString.length + 1 : 0);

    const { target } = e;
    setTimeout(() => {
      if (showAmountAdornment) {
        target.select();
      } else {
        target.setSelectionRange(numCharsToStrip, 99);
      }
    }, 0);

    if (onFocus) {
      onFocus(e);
    }
    initialValueRef.current = valueState;
  };

  const handleOnBlur = (e, wasEnter = false) => {
    if (validateField(e.target.value).trim().length > 0) {
      let newVal = validateField(e.target.value);

      const valChanged = Number(newVal) !== Number(initialValueRef.current);
      const absValChanged = Math.abs(Number(newVal)) !== Math.abs(Number(initialValueRef.current));

      if (onBlur) {
        if (valChanged) {
          newVal = quickenAmountToString(newVal, absValChanged && (proposedSignRef.current || Math.sign(initialValueRef.current)));
        }
        e.target.value = newVal;
        onBlur(e, amountType, wasEnter);
      }

      setValueState(newVal);
    } else if (e.target.value === '' && onBlur) {
      onBlur(e, amountType, wasEnter);
    }

    if (submitOnBlur && onSubmit) {
      onSubmit();
    }
  };

  const setInputRef = (el) => {
    textInput.current = el;
    if (inputRef) {
      inputRef(el);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === '.' && valueState.indexOf('.') >= 0) {
      // this is so that you can reset a selection with a period if a period is already selected
      const subStr = valueState.substring(textInput.current?.selectionStart, textInput.current?.selectionEnd);
      const hasPeriod = subStr.indexOf('.') >= 0;
      if (!hasPeriod) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
    if (event.key === 'Enter') {
      // if user hits Enter, treat like TAB and treat as a blur to capture the value
      const e = { target: { value: `${currencyString} ${valueState}` } };
      handleOnBlur(e, true);

      if (onSubmit) {
        onSubmit();
      }
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  if (editable) {
    return (
      <TextField
        {...otherProps}
        className={classNames(classes.inputField, className)}
        sharedcomponentid="AMOUNT_FIELD"
        value={`${showAmountAdornment ? '' : `${currencyString}${currencyString ? ' ' : ''}`}${valueState}`}
        onChange={handleChange}
        autoFocus={autoFocus}
        label={label}
        margin={marginProp}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
        inputRef={setInputRef}
        onKeyDown={handleKeyPress}
        variant={textFieldVariant}
        InputProps={{
          ...InputProps,
          ...(disableUnderline && { disableUnderline: true }),
          inputProps: {
            className: classes.inputField,
            maxLength: maxChars || (13 + (currencyString ? currencyString.length : 0) + (showSign === ShowSignEnum.NEVER ? 0 : 1)),
          },
          startAdornment: showAmountAdornment ? (
            <InputAdornment classes={{ root: classes.amountadornment }} disableTypography position="end">
              {currencyString}
            </InputAdornment>
          ) : (
            ''
          ),
        }}
      />
    );
  }

  return (
    <Wrap
      {...wrapProps}
    >
      <QTypography
        {...otherProps}
        variant={variant || 'inherit'}
        component="span"
        sharedcomponentid="AMOUNT_FIELD"
        className={classNames(...[classes.inputField, className, ...(colorClass ? [colorClass] : [])])}
      >
        {renderStaticValue()}
      </QTypography>
    </Wrap>
  );
};

export default withStyles(AmountField, amountFieldStyles);
