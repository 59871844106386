import React, { FC } from 'react';
import cn from 'classnames';
import { makeStyles } from 'tss-react/mui';

import Alert from '@mui/material/Alert';

import { getEnvironmentConfig } from 'companion-app-components/utils/core';

import WarningImage from 'assets/warning.svg';
import styles from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface SubscriptionAlertProps {
  label: string;
  alertClass?: string;
}

const SubscriptionAlert: FC<SubscriptionAlertProps> = ({ label, alertClass }) => {

  const { classes } = useStyles();

  return (
    <Alert 
      icon={<img src={WarningImage} alt="alert" height={24} width={24} />}
      className={cn(classes.alert, alertClass)}
    >
      {label} 
      <a href={getEnvironmentConfig()?.pick_your_plan_url} className={classes.anchorStyle} target="_blank"> Renew Now! </a>
    </Alert>
  );
};

export default SubscriptionAlert;
