import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { makeStyles } from 'tss-react/mui';

import { tracker } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import RootState from 'companion-app-components/utils/redux-store/rootState';

import useQPreferences from 'components/QPreferences/useQPreferences';
import { defaultDatasetPreferences } from 'data/preferences/defaults';
import { isOldEdge } from 'utils/utils';
import { preferenceData as PREFS_DATA } from './data';
import { styles } from './style';
import { PrefType } from './type';

const useStyles = makeStyles()(styles as Record<string, any>);

const isIe = require('is-iexplorer') || isOldEdge(); // eslint-disable-line

interface RegPrefsModalProps {
  onClose: () => void;
}

export const RegPrefsModal: React.FC<RegPrefsModalProps> = ({ onClose }) => {
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const { classes } = useStyles();
  const featureFlags = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state));

  const prefAdvancedOption = featureFlags.get('prefAdvancedOption');

  const handleCheck = (field: string) => (event: Record<string, any>, checked: boolean) => {
    if (event && event.target) {
      setDatasetPreference({ transactionRegister: { [field]: checked } });
    }
    tracker.track(tracker.events.txnPrefs, {
      type: field,
      state: checked,
    });
  };

  const closeAndExit = () => {
    onClose();
  };

  const resetDefaults = () => {
    tracker.track(tracker.events.txnPrefs, {
      type: 'reset',
    });
    setDatasetPreference({ transactionRegister: defaultDatasetPreferences.transactionRegister });
  };

  const createPreferencesItems = (prefs: Record<string, any>, prefsData: PrefType[]) => {
    const elements = prefsData.map((pref) => (
      <div style={{ display: 'flex' }} key={`RegPrefsItem-${pref.field}`}>
        <Typography className={classes.preferencesText} style={{ flexGrow: 2, marginTop: 'auto', marginBottom: 'auto' }}>
          {pref.label}
        </Typography>
        <Switch
          style={{ float: 'right' }}
          color="primary"
          checked={prefs[pref.field]}
          value={prefs[pref.field] ? 'on' : 'off'}
          onChange={handleCheck(pref.field)}
          id={pref.id}
        />
      </div>
    ));
    return elements;
  };

  const advancedPrefs = useMemo(
    () => PREFS_DATA.filter((x) => x.fflag && (featureFlags.get(x.fflag) === 'advanced' || !featureFlags.get(x.fflag))),
    [featureFlags],
  );
  const prefsData = useMemo(
    () => PREFS_DATA.filter((x) => !x.fflag || featureFlags.get(x.fflag) === true || featureFlags.get(x.fflag) === 'show'),
    [featureFlags],
  );

  const noAdvanced = advancedPrefs.length === 0;

  return (
    <Dialog open classes={isIe ? { paper: classes.dialogPaper } : {}} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle>Transactions Preferences</DialogTitle>

      <DialogContent>
        <FormControl component="fieldset">
          {datasetPreferences && datasetPreferences.transactionRegister && (
            <FormGroup>
              {createPreferencesItems(datasetPreferences.transactionRegister, prefsData)}
              {prefAdvancedOption && !noAdvanced && <Divider />}
              {advancedPrefs.length > 0 && showAdvanced && (
                <>
                  <Typography variant="body1" style={{ marginTop: 10 }} className={classes.preferencesText}>
                    Advanced Options
                  </Typography>
                  {createPreferencesItems(datasetPreferences.transactionRegister, advancedPrefs)}
                </>
              )}
            </FormGroup>
          )}
        </FormControl>
        {prefAdvancedOption && !noAdvanced && (
          <ButtonBase
            className={classes.link}
            onClick={() => setShowAdvanced(!showAdvanced)}
            style={{ marginTop: 10, display: 'block', paddingLeft: 0 }}
            id={`pref:link-advanced:${showAdvanced ? 'on' : 'off'}`}
          >
            <Typography className={classes.link}>{showAdvanced ? '<<< Hide Advanced' : 'Advanced Options >>>'}</Typography>
          </ButtonBase>
        )}
      </DialogContent>

      <div className={classes.buttonArea}>
        <Button style={{ float: 'left' }} color="primary" onClick={resetDefaults} id="txn-pref-reset-to-defaults">
          Reset To Defaults
        </Button>
        <Button style={{ float: 'right' }} color="primary" onClick={closeAndExit} id="txn-pref-done">
          Done
        </Button>
      </div>
    </Dialog>
  );
};

export default RegPrefsModal;
