import React, { FC, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { List, OrderedMap } from 'immutable';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import OutlinedFlag from '@mui/icons-material/OutlinedFlag';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VisibilityOnIcon from '@mui/icons-material/Visibility';

import { getLogger } from 'companion-app-components/utils/core';
import { datasetsUtils, datasetsTypes } from 'companion-app-components/flux/datasets';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import type RootState from 'companion-app-components/utils/redux-store/rootState';

import { getClipboardForKey } from 'data/clipboard/selectors';
import * as transactionListSelectors from 'data/transactionList/selectors';

import QCheckbox from 'components/QCheckbox';
import QTip from 'components/QuickenControls/QTip';
import ReconcilePanel from 'components/ReconcilePanel';
import TransactionCalendar from 'components/TransactionCalendar';
import RegPrefsModal from 'containers/Transactions/RegPrefsModal';
import TxPrefMenu from 'components/TransactionRegister/TxPrefMenu';
import useQPreferences from 'components/QPreferences/useQPreferences';

import { fieldIsMoveable } from '../helpers';
import RegControlBar from '../RegControlBar';
import RegMEditBar from '../RegMEditBar';
import * as txConfig from '../transactionsConfig';

import { RegHeader, HdrListItem, ColumnField, styles } from './styles';

const log = getLogger('components/RegisterHeader/index.tsx');

//
// The control bar at the top of the Transaction list (register)
//

const useStyle = makeStyles()(styles);

type TransactionListMetricsType = {
  earliestDate: DateTime;
  hasScheduledInstances: boolean;
  numTxns: number;
  reviewedCount: number;
  sumTxns: number;
};

interface RegisterHeaderProps {
  showBalance: boolean;
  uiState: Record<string, any>;
  accountIds: List<string>;
  showControls: boolean;
  headerFields: List<string>;
  hideColumnsHeader: boolean;
  innerRegRef: (event: HTMLDivElement | null) => void;
  doubleAmounts: boolean;
  showAccountColors: boolean;
  callbacks: Record<string, any>;
  acctTxns: OrderedMap<string, List<transactionsTypes.TransactionProps>>;
  noNew: boolean;
  sizeChanged: boolean;
  hideSearch: boolean;
  transactionListMetrics: TransactionListMetricsType;
  sortBy: string;
  sortOrder: string;
  draggableFields: boolean;
  calendarDate: Record<string, any>;
  showDebtErrorMessage: boolean;
  reminderSetting: string;
  dataset: datasetsTypes.Dataset;
}

const draggingOver = false;

const RegisterHeader: FC<RegisterHeaderProps> = (props) => {

  const [showRegPrefs, setShowRegPrefs] = useState(false);
  const [showReconcile, setShowReconcile] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [headerFields, setHeaderFields] = useState<List<string> | null>(props.headerFields);

  const fieldToDrop = useRef(null);
  const origList = useRef<List<string> | null>(null);
  const draggingOverRef = useRef(null);

  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyle();
  const { datasetPreferences, setDatasetPreference } = useQPreferences();

  const featureFlags = useSelector((state: RootState) => featureFlagsSelectors.getFeatureFlags(state));
  const suggestCategoryForUncategorized = featureFlags.get('suggestCategoryForUncategorized');
  const TransactionsWithCategorySuggestion = useSelector((state: RootState) => 
    transactionListSelectors.getTransactionsWithCategorySuggestions(state, { accountIds: props.accountIds }));
  const transactionsWithCategorySuggestions = suggestCategoryForUncategorized ? TransactionsWithCategorySuggestion : List();
  const accountsById = useSelector((state: RootState) => accountsSelectors.getAccountsById(state));
  const accountsFilter = useSelector((state: RootState) => accountsSelectors.getAccountsByIds(state, props.accountIds));
  const txnsCopied = useSelector((state: RootState) => getClipboardForKey(state, 'bankTransactions'))?.size > 0;
  const txnCancelExtraColumn = featureFlags.get('txnCancelExtraColumn');
  const reconcileAccount = featureFlags.get('reconcileAccount');
  const transactionCalendar = featureFlags.get('transactionCalendar');
  const scheduledTransactionsInRegister = featureFlags.get('scheduledTransactionsInRegister');
  
  useEffect(() => {
    setHeaderFields(props.headerFields);
  }, [props.headerFields]);
  
  const onDragStart = (event, field) => {
    event.dataTransfer.setData('text', field);
    fieldToDrop.current = field;
    origList.current = headerFields;
  };

  const alterHeader = (fromField, toField) => {
    const workArray = headerFields!.toArray();
    const fromIndex = workArray.indexOf(fromField);
    const initialToIndex = workArray.indexOf(toField);
    const adjust = fromIndex <= initialToIndex ? 1 : 0;

    const temp = workArray[fromIndex];
    workArray.splice(fromIndex, 1); // delete fromField from list
    const toIndex = workArray.indexOf(toField);
    workArray.splice(toIndex + adjust, 0, temp);  // insert fromField back to list either before or after the to item

    if (JSON.stringify(headerFields!.toArray()) !== JSON.stringify(workArray)) {
      setHeaderFields(List(workArray));
    }
  };

  const onDragOver = (event, field) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    draggingOverRef.current = field;
    if (fieldToDrop.current && (fieldToDrop.current !== field)) {
      alterHeader(fieldToDrop.current, field);
    }
    event.preventDefault();  // not fully sure what this prevents, but without this, the onDrop is never invoked
  };

  const saveCurrentHeader = () => {
    props.callbacks.alterHeader(headerFields);
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (fieldToDrop.current) {
      saveCurrentHeader();
    }
    fieldToDrop.current = null;
  };

  const onDragEnd = () => {
    if (fieldToDrop.current) {
      fieldToDrop.current = null;
      draggingOverRef.current = null;
      setHeaderFields(origList.current);
    }
  };

  const maybeCancel = () => {
    props.callbacks.maybeSaveTxnBeingEdited(() => {
      props.callbacks.cancelEdit();
    });
  };

  const handleSettingsChange = (value) => {

    switch (value) {
      case 'compact':
      case 'comfortable':
      case 'normal':
        setDatasetPreference({ transactionRegister: { registerComfort: value } });
        break;
      case 'preferences':
        setShowRegPrefs(true);
        break;
      case 'reconcile':
        if (reconcileAccount) {
          setShowReconcile(true);
        }
        break;
      case 'calendar':
        if (transactionCalendar) {
          setShowCalendar(true);
        }
        break;
      default:
        break;
    }
  };

  const closeRegPrefs = () => {
    setShowRegPrefs(false);
  };

  const defaultHeaderItem = (field, sortClass, extraClasses) => (
    <HdrListItem
      id={`${field}-header`}
      privateKey={`regHdr:${field}`}
      fieldName={field}
      onClick={(e) => props.callbacks?.headerClick?.(e, field)}
      {...props}
      {...extraClasses}
    >
      <ColumnField>
        {txConfig.fieldData[field].label}
      </ColumnField>
      {props.sortBy === field &&
      <ArrowUpwardIcon
        className={classes[sortClass]}
        viewBox="-1 -1 27 27"
      />}
    </HdrListItem>
  );

  const renderFields = (options) => {

    const { callbacks } = props;
    const { sortClass, antiSortClass, showBalance } = options;

    const retRow: React.JSX.Element[] = [];

    headerFields?.forEach((field) => {
      let privateClassName;

      switch (field) {
        case 'action':
          privateClassName = classNames(classes.regItem, 'click', 'protectWidth');
          retRow.push(defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        case 'security':
          privateClassName = classNames(classes.regItem, 'click', 'protectWidth');
          retRow.push(defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        case 'quantity':
          privateClassName = classNames(classes.regItem, 'click', 'quantity', 'amount');
          retRow.push(defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        case 'price':
          privateClassName = classNames(classes.regItem, 'click', 'protectWidth', 'amount');
          retRow.push(defaultHeaderItem(field, sortClass, { privateClassName }));
          break;
        //--------------------------------------------
        // MATCH FIELD
        //--------------------------------------------
        case 'match':
          retRow.push(
            <HdrListItem
              id="match-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classes.iconColumn}
            >
            </HdrListItem>,
          );
          break;

        //--------------------------------------------
        // REVIEWED FIELD
        //--------------------------------------------
        case 'reviewed': {
          // const filterState = props.uiState.reviewedFilterState;
          // const CheckCircleComponent = filterState !== 'none' ? CheckCircleIcon : CheckCircleOutline;
          retRow.push(
            <HdrListItem
              id="reviewed-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.iconButtonColumn)}
              onClick={(e) => callbacks.headerClick(e, 'reviewed')}
            >
            </HdrListItem>,
          );
          break;
        }
        //--------------------------------------------
        // FLAG FIELD
        //--------------------------------------------
        case 'userFlag':
          retRow.push(
            <HdrListItem
              id="flag-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classes.iconButtonColumn}
            >
              <OutlinedFlag
                style={{ fontSize: 20 }}
              />
            </HdrListItem>,
          );
          break;

        //--------------------------------------------
        // IGNORED FIELD
        //--------------------------------------------
        case 'ignored':
          retRow.push(
            <HdrListItem
              id="ignored-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classes.iconButtonColumn}
            >
              <VisibilityOnIcon
                style={{ fontSize: 20 }}
              />
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // SELECT FIELD
        //--------------------------------------------
        case 'select':
          retRow.push(
            <HdrListItem
              id="regHdrSelectAll"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classes.iconButtonColumn}
            >
              {/* @ts-expect-error: missing properties */}
              <QTip
                title="Select / Unselect all visible transactions"
              >
                <QCheckbox
                  size={theme.components.register.checkboxSize}
                  color="primary"
                  inputProps={{ 'aria-label': 'Search Transactions' }}
                  className={classes.checkBox}
                  onChange={(e, v) => {
                    callbacks.onCheckAll(v);
                  }}
                  checked={props.uiState.allSelected}
                />
              </QTip>
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // ACCOUNT NAME FIELD
        //--------------------------------------------
        case 'account': {
          const hide = props.showAccountColors || props.accountIds.size === 1;
          const hideClass = hide ? classes.nop : '';
          const sizeClass = props.showAccountColors ? '' : 'protectWidth';

          retRow.push(
            <HdrListItem
              id="account-name-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={
                classNames(
                  classes.regItem,
                  'click',
                  classes.accountFieldName,
                  sizeClass,
                  hideClass,
                )
              }
              style={{ cursor: 'default' }}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
            </HdrListItem>,
          );
          break;
        }

        //--------------------------------------------
        // ACCOUNT COLOR FIELD
        //--------------------------------------------
        case 'accountColor': {

          const hideClass = !props.showAccountColors ? classes.nop : '';
          retRow.push(
            <HdrListItem
              id="account-color-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.accountFieldColor, hideClass)}
            >
              <div
                className={classes.accountFieldColor}
              >
              </div>
            </HdrListItem>,
          );
          break;
        }

        //--------------------------------------------
        // POSTED_ON FIELD
        //--------------------------------------------
        case 'postedOn':
          retRow.push(
            <HdrListItem
              id="posted-on-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.regItem, 'click', 'protectWidthDate')}
              onClick={(e) => callbacks.headerClick(e, 'date')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {props.sortBy === 'date' &&
                <ArrowUpwardIcon
                  className={classes[sortClass]}
                  viewBox="0 0 29 29"
                />}
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // CHECKNUM FIELD
        //--------------------------------------------
        case 'check':
          retRow.push(
            <HdrListItem
              id="checknum-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.regItem)}
              onClick={(e) => callbacks.headerClick(e, 'check')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {props.sortBy === 'check' &&
                <ArrowUpwardIcon
                  className={classes[sortClass]}
                  viewBox="0 0 29 29"
                />}
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // TAGS FIELD
        //--------------------------------------------
        case 'tags':
          retRow.push(
            <HdrListItem
              id="tags-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.regItem, 'flex2')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // PAYEE FIELD
        //--------------------------------------------
        case 'payee':
          retRow.push(
            <HdrListItem
              id="payee-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.regItem, 'flex2', 'click')}
              onClick={(e) => callbacks.headerClick(e, 'payee')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {props.sortBy === 'payee' &&
                <ArrowUpwardIcon
                  className={classes[sortClass]}
                  viewBox="-1 -1 27 27"
                />}
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // NOTES FIELD
        //--------------------------------------------
        case 'notes':
          retRow.push(
            <HdrListItem
              id="notes-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.regItem, 'flex2', 'click')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // CATEGORY FIELD
        //--------------------------------------------
        case 'category':
          retRow.push(
            <HdrListItem
              id="category-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.regItem, 'flex2 click')}
              onClick={(e) => callbacks.headerClick(e, 'category')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {props.sortBy === 'category' &&
                <ArrowUpwardIcon
                  className={classes[sortClass]}
                  viewBox="-1 -1 27 27"
                />}
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // SPLIT FIELD
        //--------------------------------------------
        case 'split':
          retRow.push(
            <HdrListItem
              id="split-header"
              privateKey="regHdr:split"
              privateClassName={classes.iconColumn}
            >
              { /*
              <MUISplitIcon
                className={classes.regIcon}
                viewBox="-2 -0 27 27"
              />
              */}
            </HdrListItem>,
          );
          break;

        //--------------------------------------------
        // STATE FIELD
        //--------------------------------------------
        case 'state':
          retRow.push(
            <HdrListItem
              id="state-header"
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.statusColumn, 'click')}
              onClick={(e) => callbacks.headerClick(e, 'status')}
            >
              <ColumnField>
                {txConfig.fieldData[field].label}
              </ColumnField>
              {props.sortBy === 'status' &&
                <ArrowUpwardIcon
                  className={classes[sortClass]}
                  viewBox="-1 -1 27 27"
                />}
            </HdrListItem>,
          );
          break;
        //--------------------------------------------
        // ATTACHMENT FIELD
        //--------------------------------------------
        case 'attachment':
          if (props.uiState.showAttachmentColumnInHeader) {
            retRow.push(
              <HdrListItem
                id="attachment-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classes.iconColumn}
              >
                {
                  <AttachFileIcon
                    className={classes.regIcon}
                    viewBox="-2 -4 30 30"
                  />
                }
              </HdrListItem>,
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classes.iconButtonColumn}
              />,
            );
          }
          break;
        //--------------------------------------------
        // EXPENSE FIELD
        //--------------------------------------------
        case 'expense':
          if (props.doubleAmounts) {
            retRow.push(
              <HdrListItem
                id="expense-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(classes.regItem, 'amount', 'click', 'protectWidth')}
                onClick={(e) => callbacks.headerClick(e, 'expense')}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
                {props.sortBy === 'amount' &&
                  <ArrowUpwardIcon
                    className={classes[sortClass]}
                    viewBox="-1 -1 27 27"
                  />}
              </HdrListItem>,
            );
          } else {
            retRow.push(
              <HdrListItem
                privateClassName={classes.nop}
                fieldName={`${field}`}
                privateKey={`regHdr:${field}`}
              />,
            );
          }
          break;
        //--------------------------------------------
        // INCOME FIELD
        //--------------------------------------------
        case 'income':
          if (props.doubleAmounts) {
            retRow.push(
              <HdrListItem
                id="income-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(classes.regItem, 'amount', 'click', 'protectWidth')}
                onClick={(e) => callbacks.headerClick(e, 'income')}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
                {props.sortBy === 'amount' &&
                  <ArrowUpwardIcon
                    className={classes[antiSortClass]}
                    viewBox="-1 -1 27 27"
                  />}
              </HdrListItem>,
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classes.nop}
              />,
            );
          }
          break;
        //--------------------------------------------
        // AMOUNT FIELD
        //--------------------------------------------
        case 'amount':
          if (!props.doubleAmounts) {
            retRow.push(
              <HdrListItem
                id="amount-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(classes.regItem, 'amount', 'click', 'protectWidth')}
                onClick={(e) => callbacks.headerClick(e, 'amount')}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
                {props.sortBy === 'amount' &&
                  <ArrowUpwardIcon
                    className={classes[sortClass]}
                    viewBox="-1 -1 27 27"
                  />}
              </HdrListItem>,
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classes.nop}
              />,
            );
          }
          break;
        //--------------------------------------------
        // BALANCE
        //--------------------------------------------
        case 'balance':
          if (showBalance) {
            retRow.push(
              <HdrListItem
                id="balance-header"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames('amount', classes.regItem)}
              >
                <ColumnField>
                  {txConfig.fieldData[field].label}
                </ColumnField>
              </HdrListItem>,
            );
          } else {
            retRow.push(
              <HdrListItem
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classes.nop}
              />,
            );
          }
          break;
        //--------------------------------------------
        // MENU FIELD
        //--------------------------------------------
        case 'menu': {

          const customItems: { label: string, value: string }[] = [];

          if (transactionCalendar) {
            customItems.push({
              label: 'Calendar...',
              value: 'calendar',
            });
          }

          if (props.accountIds.size === 1 && reconcileAccount) {
            customItems.push({
              label: 'Reconcile Account...',
              value: 'reconcile',
            });
          }

          // This actually should be getting the accountNode id for superRegisters, but we don't have access
          // to that. Since right now only CREDIT registers have their own regFields sort order, and since
          // the first account in this group will be of type credit, this actually works, but we need to do
          // a more siginficant refactoring
          //
          const firstAccount = accountsById?.get(props.accountIds?.first()) || accountsById?.first();
          const accountType = firstAccount?.type || 'bank';

          retRow.push(
            <HdrListItem
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classes.iconButtonColumn}
            >
              <ColumnField>
                {!props.noNew &&
                  <TxPrefMenu
                    colorBlack
                    selected={datasetPreferences.transactionRegister.registerComfort}
                    onChange={(v) => handleSettingsChange(v)}
                    customItems={customItems}
                    accountType={accountType}
                  />}
              </ColumnField>
            </HdrListItem>,
          );
          break;
        }

        //--------------------------------------------
        // SAVE FIELD
        //--------------------------------------------
        case 'save':
          retRow.push(
            <HdrListItem
              privateKey={`regHdr:${field}`}
              fieldName={`${field}`}
              privateClassName={classNames(classes.iconButtonColumn, 'skinny')}
            />,
          );
          break;

        //--------------------------------------------
        // CLOSE FIELD
        //--------------------------------------------
        case 'close':
          if (txnCancelExtraColumn) {
            retRow.push(
              <HdrListItem
                id="regHdrSelectAll"
                privateKey={`regHdr:${field}`}
                fieldName={`${field}`}
                privateClassName={classNames(classes.iconButtonColumn, 'skinny')}
              >
              </HdrListItem>,
            );
          }
          break;


        default:
          log.error(`field type ${field} not implemented`);
          break;
      }
    });
    return retRow;
  };

  const renderTransactionsHeader = () => {

    const { showBalance, sortOrder, accountIds, uiState, 
      callbacks, calendarDate, innerRegRef, showControls, hideSearch, acctTxns,
      transactionListMetrics, reminderSetting, noNew, dataset, showDebtErrorMessage, 
      hideColumnsHeader, draggableFields } = props;
    const sortClass = sortOrder === 'ascending' ? 'turnUp' : 'turnDown';
    const antiSortClass = sortOrder === 'descending' ? 'turnUp' : 'turnDown';
    const multiAccount = accountIds.size !== 1;  // TODO what if there is only 1 account?
    const fieldItems = renderFields({ multiAccount, sortClass, antiSortClass, showBalance });
    const txnsAreSelected = uiState.selectedTxns?.size > 0 || (!multiAccount && txnsCopied);
    const doFastCat = transactionsWithCategorySuggestions &&
      transactionsWithCategorySuggestions.size > 0 &&
      suggestCategoryForUncategorized;
    
    return (
      <div>
        {showRegPrefs &&
          <RegPrefsModal
            onClose={closeRegPrefs}
          >
          </RegPrefsModal>}

        {showReconcile &&
          <ReconcilePanel
            onClose={() => setShowReconcile(false)}
            accountId={accountIds.first()}
          >
          </ReconcilePanel>}

        {showCalendar &&
          <TransactionCalendar
            onClose={() => setShowCalendar(false)}
            navFn={(txn) => callbacks.scrollToTransaction(txn.id)}
            inDialog
            year={calendarDate.get('calendarYear')}
            month={calendarDate.get('calendarMonth')}
            setDate={callbacks.setCalendarDate}
            accountIds={accountIds}
          >
          </TransactionCalendar>}

        <RegHeader
          onClick={maybeCancel}
          ref={
            (e) => {
              if (innerRegRef) {
                return innerRegRef(e);
              }
              return null;
            }
          }
        >
          {showControls && !txnsAreSelected &&
            <div>
              {!hideSearch &&
              <RegControlBar
                onFastCategorize={doFastCat ? (e) => {
                  e.stopPropagation();
                  callbacks.fastCategorize();
                } : undefined}
                // @ts-expect-error: Property 'onRefresh' does not exist on type
                onRefresh={callbacks.refreshTxnList}
                onSearch={callbacks.onSearchFilter}
                filter={uiState.searchBoxFilter}
                onApplyFilter={callbacks.onApplyFilter}
                filterObject={uiState.filterObject}
                downloadFile={callbacks.downloadFile}
                acctTxns={acctTxns}
                transactionListMetrics={transactionListMetrics}
                scheduledTransactionsInRegister={scheduledTransactionsInRegister}
                reminderSetting={reminderSetting}
                onReminderChange={accountIds.size === 1 && callbacks.reminderChange ? callbacks.reminderChange : undefined}
                showNewTxn={callbacks.showNewTxn}
                noNew={noNew}
                isWindowsDataset={dataset && datasetsUtils.isWindowsDataset(dataset)}
                showDebtErrorMessage={showDebtErrorMessage}
                accountsFilter={accountsFilter}
                defaultAccountId={accountIds.first()}
              />}
            </div>}


          {txnsAreSelected &&
            <RegMEditBar
              selectedTxns={uiState.selectedTxns}
              onEdit={callbacks.editSelectedTxns}
              onDelete={callbacks.deleteSelectedTxns}
              onReviewed={() => callbacks.reviewSelectedTxns(true)}
              onNotReviewed={() => callbacks.reviewSelectedTxns(false)}
              onMatch={callbacks.matchSelectedTxns}
              onDownload={callbacks.downloadFile}
              accountIds={accountIds}
            />}

          {!hideColumnsHeader &&
            <div className={classes.regColumnsHeader}>
              {
                fieldItems.map((item) => {

                  const draggableDroppable = draggableFields && fieldIsMoveable(item.props.fieldName);

                  return (
                    <div
                      onDragStart={draggableDroppable ? (e) => onDragStart(e, item.props.fieldName) : undefined}
                      onDragOver={draggableDroppable ? (e) => onDragOver(e, item.props.fieldName) : undefined}
                      onDrop={draggableDroppable ? onDrop : undefined}
                      onDragEnd={draggableDroppable ? onDragEnd : undefined}
                      key={`hdrColumn-${item.props.privateKey}`}
                      draggable={draggableDroppable}
                      className={
                        classNames(
                          item.props.privateClassName,
                          draggingOver === item.props.fieldName ? classes.inserting : '',
                        )
                      }
                    >
                      {item}
                    </div>
                  );
                })
              }
            </div>}
        </RegHeader>
      </div>
    );
  };

  return renderTransactionsHeader();
};

export default RegisterHeader;
