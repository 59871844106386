import React, { FC, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from 'tss-react/mui';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';

import { getLogger } from 'companion-app-components/utils/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { colorLookup } from 'companion-app-components/flux/preferences/utils';

import useQPreferences from 'components/QPreferences/useQPreferences';

import { styles } from './styles';

const log = getLogger('components/QuickenControls/AccountsMenu/index.tsx');

const useStyles = makeStyles()(styles);

interface AccountsMenuProps {
  selected: string;
  onChange: (value: string) => void;  
  element: Element;
  onClose: () => void;
  showColors: boolean;
}

const AccountsMenu: FC<AccountsMenuProps> = ({
  selected,
  onChange,
  element,
  onClose,
  showColors,
}) => {

  const [currentSelected, setCurrentSelected] = useState(selected);

  const accounts = useSelector((state) => accountsSelectors.getTransactionAccounts(state));
  const { accountPreferencesById, datasetPreferences } = useQPreferences();

  const { classes } = useStyles();

  const options = useMemo(() => accounts.map((account) => {
    const color = accountPreferencesById[account.id]?.color;
    return {
      color: colorLookup(color),
      label: account.name,
      value: account.id,
    };
  }), [accounts, accountPreferencesById]);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  const open = (element !== null);
  const isSelected = Boolean(selected);

  const handleRequestClose = () => {
    log.log('REQUEST CLOSE');
  };

  const keyDown = (e) => {
    if (e.key === 'Enter' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'Tab') {
      e.stopPropagation();
    }
    if (e.key === 'Escape') {
      if (onClose) {
        e.stopPropagation();
        onClose();
      }
    }
  };

  const onExited = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleClick = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const makeCheckListItem = (item, selectedFlag) => {

    const id = item.value;
    const { transactionRegister: regPrefs } = datasetPreferences;

    return (
      <ListItem
        dense
        button
        onClick={() => handleClick(id)}
        key={`qmenu_li_${item.value}`}
        disableGutters
      >
        {selectedFlag &&
          <ListItemIcon className={classes.listIcon}>
            <CheckIcon />
          </ListItemIcon>}
        {!selectedFlag &&
          <div className={classes.iconFiller} />}

        {regPrefs.showAccountColors &&
          <div
            className={classes.colorBar}
            style={{ opacity: showColors ? 1 : 1, background: item.color }}
          />}

        <ListItemText
          className={classes.listText}
          primary={item.label}
        />
      </ListItem>
    );
  };

  const renderOptions = () => {
    const data: any = [];
    options.forEach((item) => {
      if (item.isDivider) {
        data.push(<Divider key={uuidv4()} />);
      } else if (item.isSubheader) {
        data.push(
          <ListSubheader key={`acctmenu_sub_${item.label}`}>
            <ListItemText
              className={classes.menuSubHead}
              secondary={item.label}
            />
          </ListSubheader>,
        );
      } else if (isSelected) {
        data.push(makeCheckListItem(item, currentSelected === item.value));
      } else {
        data.push(
          <MenuItem
            key={`acctmenu_li_${item.value}`}
            dense
            onClick={() => handleClick(item.value)}
            disableGutters
          >
            <ListItemText
              className={classes.listText}
              primary={item.label}
            />
          </MenuItem>,
        );
      }
    });
    return data;
  };

  return (
    <Menu
      id="long-menu"
      open={open}
      anchorEl={element}
      onClose={handleRequestClose}
      onKeyDown={keyDown}
      onBackdropClick={onClose || null}
      classes={{ paper: classes.menuRoot }}
      TransitionProps={{
        onExited,
      }}
    >
      {renderOptions()}
    </Menu>
  );
};

export default AccountsMenu;
