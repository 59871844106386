import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';

import compose from 'utils/compose';
import ProfileAvatar from 'containers/App/Header/ProfileMenu/ProfileAvatar';
import SubscriptionAlert from 'containers/ProfilePage/SubscriptionAlert';
import WarningImage from 'assets/warning.svg';
import { styles } from './styles';

class MiniDatasetPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (value) => {
    this.setState({ anchorEl: null });
    if (this.props.onChange && value) {
      this.props.onChange(value);
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderDatasets = (theDataset, index, dataset, ownDataset = false) => {
    const hasValidEntitlement = this.props.entitledDatasets.get(theDataset.id);
    const { subscription } = this.props;
    const noDatasetOrEntitlement = !ownDataset && !hasValidEntitlement;
    const isDisabled = noDatasetOrEntitlement || (ownDataset && !subscription?.active);
    return (
      <MenuItem
        button="true"
        key={theDataset.id}
        selected={dataset === theDataset}
        onClick={() => this.handleMenuItemClick(theDataset)}
        disabled={isDisabled}
        sx={{
          '&.Mui-disabled': {
            opacity: 1, 
          },
        }}
      >
        <ListItemIcon
          style={{
            visibility: `${(dataset === theDataset) || noDatasetOrEntitlement ? 'visible' : 'hidden'}`,
            marginRight: 0,
          }}
        >
          { noDatasetOrEntitlement ? <img src={WarningImage} alt="alert" height={24} width={24} /> : <CheckIcon /> }
        </ListItemIcon>
        <ListItemText 
          sx={{
            opacity: isDisabled ? 0.5 : 1, 
          }}
          primary={
            <div className={classNames(this.props.classes.textEllipsis, this.props.classes.datasetNameWrapper)}>
              {theDataset.name}
            </div>
          }
          secondary={ownDataset 
            ? null 
            : (
              <Typography 
                variant="caption" 
                className={this.props.classes.ownerName}
              >
                {noDatasetOrEntitlement ? '(Owner subscription expired)' : ''}
              </Typography>
            )}
          id={index === 0 ? 'my-account-1st-data-set' : ''} 
        />
        { ownDataset && <ProfileAvatar datasetId={theDataset.id} /> }
      </MenuItem>
    );
  };

  render() {
    const { anchorEl } = this.state;
    const { ownDatasets, sharedDatasets, dataset, classes, id, subscription } = this.props;
    return (
      <>
        <Button
          classes={this.props.labelStyle || { label: classes.labelStyle }}
          aria-owns={anchorEl ? 'dataset-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          sx={{ height: '100%', textTransform: 'none', fontSize: 16 }}
          id={id}
        >
          {this.props.label || 'CHANGE'}
        </Button>
        <Menu
          id="dataset-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          style={{ zIndex: 1302 }}
        >
          {
            ownDatasets.size > 0 && 
            <Typography color="inherit" variant="subtitle1" className={classes.datasetTitle}>
              My Cloud Accounts
            </Typography>
          }
          {
            subscription !== null && !subscription?.active && <SubscriptionAlert
              alertClass={classes.alert}
              label="Subscription expired. Shared Cloud Accounts are still available."
            />
          }
          {ownDatasets.valueSeq().map((theDataset, index) => this.renderDatasets(theDataset, index, dataset, true))}
          {
            sharedDatasets.size > 0 && 
            <>
              <Typography color="inherit" variant="subtitle1" className={classes.datasetTitle}>
                Shared with me
              </Typography>
              {sharedDatasets.valueSeq().map((theDataset, index) => this.renderDatasets(theDataset, index, dataset, false))}
            </>
          }
        </Menu>
      </>
    );
  }
}

MiniDatasetPicker.propTypes = {
  dataset: PropTypes.any.isRequired,
  ownDatasets: PropTypes.any.isRequired,
  sharedDatasets: PropTypes.any.isRequired,
  entitledDatasets: PropTypes.any.isRequired,
  subscription: PropTypes.any.isRequired,
  label: PropTypes.string,
  labelStyle: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  classes: PropTypes.object,
};

export default compose(
  withTheme,
)(withStyles(MiniDatasetPicker, styles, { name: { MiniDatasetPicker } }));
