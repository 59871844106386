import React, { FC, useState, useEffect, CSSProperties, useRef, KeyboardEvent, 
  ClipboardEvent, MouseEvent, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
  pruneString,
  formatNumber,
  chopValue,
  signFromValue,
  operatorFromSign,
  getCaretPosition,
  setCaretPosition,
} from './utils';

import { amountSpanStyle } from './styles';

interface AmountSpanProps {
  variant?: string;
  initialValue: number;
  negator?: number;
  onBlur?: (value: number) => void;
  className?: string;
  style?: CSSProperties;
  currencyString?: string;
  showSign?: boolean;
  onChange?: (value: number) => void;
  editable?: boolean;
  underlineOnFocus?: boolean;
  focusedOnMount?: boolean;
  clearFieldOnClick?: boolean;
  id?: string;
  shouldStopProp?: boolean;
  onFocus?: () => void;
}

const useStyles = makeStyles()(amountSpanStyle as Record<string, any>);

const AmountSpan: FC<AmountSpanProps> = ({ 
  initialValue,
  onBlur,
  className,
  onChange,
  style = {},
  variant = 'body1',
  editable = true,
  currencyString = 'USD',
  showSign = false,
  negator = -1,
  id = 'amountSpan',
  underlineOnFocus = false,
  focusedOnMount = false,
  clearFieldOnClick = false,
  shouldStopProp = true,
  onFocus = () => {},
  ...otherProps
}) => {

  const [amount, setAmount] = useState(initialValue);
  const [sign, setSign] = useState(signFromValue(initialValue));
  const spanRef = useRef<HTMLSpanElement>(null);
  const [focused, setFocus] = useState(false);
  const [chop, setChop] = useState(false);

  const { classes, cx, theme } = useStyles();

  useEffect(() => {
    setAmount(initialValue);
    setSign(signFromValue(initialValue));
    if (spanRef.current) {
      spanRef.current?.blur();
      spanRef.current.textContent = formatNumber(initialValue);
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (focusedOnMount && spanRef.current) {
      spanRef.current?.focus();
    }
  }, [focusedOnMount]);

  const handleChange = useCallback(() => {
    if (onChange && spanRef.current) {
      onChange(Number(chopValue(pruneString(spanRef.current?.innerText))));
    }
  }, [onChange]);

  useEffect(() => {
    const node = spanRef.current;
    if (node && onChange) {
      node.addEventListener('DOMSubtreeModified', handleChange);
    }
    return () => {
      if (node) {
        node.removeEventListener('DOMSubtreeModified', handleChange);
      }
    };
  }, [onChange, handleChange]);

  useEffect(() => {
    if (chop && spanRef.current) {
      const node = spanRef.current;
      const caretPosition = getCaretPosition(node);
      node.textContent = chopValue(node.innerText);
      setCaretPosition(node, caretPosition);
      setChop(false);
    }
  }, [chop]);

  let update = true; // controls how to handle the blur (don't update on escape)

  const amtFocus = () => {
    onFocus();
    setFocus(true);
    if (spanRef.current) {
      spanRef.current.textContent = pruneString(spanRef.current?.innerText);
    }
    update = true;
  };


  const amtKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
    let selectedChars;
    const currentString = spanRef.current?.innerText || '';
    const keyNum = e.keyCode || e.which;
    switch (keyNum) {
      case 8: // backspace
        break;
      case 13: // enter
        spanRef.current?.blur();
        break;
      case 27: // escape
        update = false;
        spanRef.current?.blur();
        break;
      case 65: // a
      case 67: // c
      case 86: // v
        if (!(e.metaKey || e.ctrlKey)) { // allow copy and paste and select
          e.preventDefault();
        }
        break;
      case 110: // decimal
      case 190: // period
        if (currentString.length > 10) {
          e.preventDefault();
        }
        if (currentString && currentString.indexOf('.') >= 0) {
          selectedChars = window.getSelection && window.getSelection()?.getRangeAt(0).toString();
          if (!selectedChars || selectedChars.indexOf('.') === -1) { // if period is not selected, prevent period entry
            e.preventDefault();
          }
        }
        break;
      default:
        if (e.key === '+' || e.key === '-') { // key is a sign
          setSign(e.key);
          e.preventDefault();
        } else if (keyNum === 32 || (keyNum >= 48 && keyNum <= 222)) {
          if ('0123456789'.indexOf(e.key) === -1) {
            e.preventDefault(); // invalid keys
          } else if (currentString.length > 10) {
            e.preventDefault(); // valid digit but too long
          } else {
            const caretPosition = getCaretPosition(spanRef.current);
            const periodIndex = currentString.indexOf('.');
            if (periodIndex >= 0 && caretPosition > periodIndex && currentString.length > periodIndex + 2) {
              if (caretPosition === currentString.length) {
                e.preventDefault();
              } else { // truncate post-decimal submissions
                setChop(true);
              }
            }
          }
        }
        break;
    }
  };

  const amtBlur = () => {
    setFocus(false);
    if (update && spanRef.current) {
      const signedValue = parseFloat(spanRef.current?.innerText || '0').toFixed(2) * operatorFromSign(sign, negator);
      // update amount if different
      if (Number(signedValue) !== Number(amount)) {
        setAmount(signedValue);
        if (onBlur) {
          onBlur(signedValue);
        }
      }
      spanRef.current.textContent = formatNumber(spanRef.current?.innerText);
    } else if (spanRef.current) { // reformat last valid amount
      spanRef.current.textContent = formatNumber(amount);
    }
  };

  const amtPaste = (e: ClipboardEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    let text = clipboardData?.getData('Text'); // only keep textData
    text = text && pruneString(text); // only insert valid text
    window.document.execCommand('insertText', false, text);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (shouldStopProp) {
      e.stopPropagation();
    }
    if (!focused && spanRef.current) {
      spanRef.current.focus();
    }
    if (clearFieldOnClick && spanRef.current?.textContent === '0.00') {
      spanRef.current.textContent = '';
    }
  };

  return (
    <div
      role="presentation"
      onClick={editable ? handleClick : () => {}}
      className={cx((editable && (focused ? ((underlineOnFocus && classes.underlineFocused) || classes.focused) : classes.normal)) || classes.root, className)}
      style={{
        ...theme.typography[variant],
        lineHeight: '100%',
        ...style,
      }}
      id={`${id}-root`}
      sharedcomponentid={'AMOUNT_SPAN'} // eslint-disable-line react/no-unknown-property 
      onFocus={onFocus}
      {...otherProps}
    >
      <span style={{ paddingLeft: 5 }}>
        {`${(showSign || sign !== signFromValue(negator)) ? sign : ''}${getSymbolFromCurrency(currencyString || 'N/A')}`}
      </span>
      <span
        aria-label="amount"
        id={`${id}-span`}
        role="textbox"
        tabIndex={editable ? 0 : undefined}
        onKeyDown={amtKeyDown}
        onBlur={amtBlur}
        onFocus={amtFocus}
        ref={spanRef}
        contentEditable={editable}
        className={classes.editSpan}
        onPaste={amtPaste}
      />
    </div>
  );
};

export default AmountSpan;
