import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce, usePrevious } from 'react-use';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import classNames from 'classnames';
import { List } from 'immutable';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { getLogger } from 'companion-app-components/utils/core';
import RootState from 'companion-app-components/utils/redux-store/rootState';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { Account } from 'companion-app-components/flux/accounts/accountsTypes';

import compose from 'utils/compose';
import QData from 'components/QData';
import { formatNumber } from 'components/QuickenControls/AmountField';
import useQDialogs from 'components/QDialogs/useQDialogs';
import { getReconcileInfoForAccount } from 'data/reconcileList/selectors';
import TransactionSelectList from './TransactionSelectList';

const log = getLogger('component/ReconcileWorkspace/index.tsx');

interface ReconcileWorkspaceProps {
  statementEndDate: string | null;
  statementEndBalance: number;
  account: Account;
  classes: Record<string, any>;
  onClose: (status: string) => void;
  qDataSaveTransactions: (txns: Record<string, any>) => void;
}

/**
 * Presents the form to bulk edit a group of transactions
 */
const ReconcileWorkspace: FC<ReconcileWorkspaceProps> = (props) => {
  const { dialogAlert } = useQDialogs();
  const statementEndDate = props.statementEndDate ?? '';

  const round2 = (value) => Math.round(value * 100) / 100;

  const calculateState = (newReconcileInfo, newStatementEndBalance) => {
    let payments = List();
    let deposits = List();
    if (newReconcileInfo && newReconcileInfo.get('reconcileTxns')) {
      payments = newReconcileInfo.get('reconcileTxns').filter((reconcileTxn) => reconcileTxn.amount < 0);
      deposits = newReconcileInfo.get('reconcileTxns').filter((reconcileTxn) => reconcileTxn.amount >= 0);
    }
    const difference = round2(newReconcileInfo.get('clearedBalance')) - round2(newStatementEndBalance);

    return { payments, deposits, difference };
  };

  const reconcileInfo = useSelector((rootState: RootState) => getReconcileInfoForAccount(rootState, {
    accountId: props.account.id,
    statementDate: statementEndDate,
    statementEndingBalance: props.statementEndBalance,
  }));
  const [state, setState] = useState(calculateState(reconcileInfo, props.statementEndBalance));

  const convertClearedToReconciled = (txns) => {
    if (!txns) { return; }
    const newTxns = txns.filter((txn) => txn.state === 'CLEARED').map((txn) => txn.set('state', 'RECONCILED'));
    props.qDataSaveTransactions(newTxns);
  };

  const clearAll = () => {
    const txns = reconcileInfo.get('reconcileTxns');
    const newTxns = txns.map((txn) => txn.state !== 'CLEARED' ? txn.set('state', 'CLEARED') : null).filter((txn) => txn);
    props.qDataSaveTransactions(newTxns);
  };

  const unclearAll = () => {
    const txns = reconcileInfo.get('reconcileTxns');
    const newTxns = txns.map((txn) => txn.state === 'CLEARED' ? txn.set('state', 'PENDING') : null).filter((txn) => txn);
    props.qDataSaveTransactions(newTxns);
  };

  const closeAndExit = (status: string) => {
    props.onClose(status);
  };

  const onKeyDown = () => {
    // TODO:: Keeping the old commented code after TS & FC migration
    // const el = document.getElementById('reconcile-payments');
    // if (el && e.key === 'ArrowRight') {
    //   el.focus();
    // }
  };

  const onSave = () => {
    // convert all cleared transactions in the window to reconciled
    // if the balance is zero.  If not, offer to make a balance adjustment
    if (state.difference === 0) {
      convertClearedToReconciled(reconcileInfo.get('reconcileTxns'));
      setTimeout(() => closeAndExit('completed'), 500);
    } else {
      dialogAlert(
        'Balances do not match',
        'The total of items you have marked is ' +
        `${formatNumber(state.difference, props.account.currency, '0,00.00')} more than  ` +
        'the total of the items from your bank.',
        (ret) => {
          if (ret.btnPressed === 'Create a balance adjustment') {
            const newBal = round2(props.statementEndBalance);
            const balAdjustTxn = new transactionsTypes.CashFlowTransaction(
              {
                accountId: props.account.id,
                amount: -state.difference,
                isReviewed: true,
                state: 'RECONCILED',
                payee: 'Reconciliation Balance Adjustment',
                memo: `Requested cleared balance on ${DateTime.fromISO(statementEndDate).toFormat('M/d/yyyy')}` +
                  ` to be ${numeral(newBal).format('0,00.00')}`,
                postedOn: statementEndDate,
                coa: {
                  type: 'BALANCE_ADJUSTMENT',
                  id: '0',
                },
              },
            );
            props.qDataSaveTransactions([balAdjustTxn]);

            convertClearedToReconciled(reconcileInfo.get('rec~oncileTxns'));
            setTimeout(() => closeAndExit('completed'), 500);
          }
        },
        ['Create a balance adjustment', 'return to reconcile'],
      );
    }
  };

  const transactionClick = (txn) => {
    const newTxn = txn.set('state', txn.state === 'CLEARED' ? 'PENDING' : 'CLEARED');
    props.qDataSaveTransactions([newTxn]);
  };

  const renderReconcile = () => {
    if (reconcileInfo && reconcileInfo.get('reconcileTxns')) {
      return (
        <Paper elevation={0}>
          <div className={props.classes.divFlex}>
            <TransactionSelectList
              onClick={transactionClick}
              title="Payments and Checks"
              transactions={state.payments}
              className={props.classes.txList}
              showTotals
              onKeyDown={onKeyDown}
              id="reconcile-payments"
              currencyString={props.account.currency}
              accountType={props.account.type}
            />
            <TransactionSelectList
              onClick={transactionClick}
              title="Deposits"
              transactions={state.deposits}
              className={classNames(props.classes.txList, 'right')}
              showTotals
              onKeyDown={onKeyDown}
              id="reconcile-deposits"
              currencyString={props.account.currency}
              accountType={props.account.type}
            />
          </div>
        </Paper>
      );
    }
    return [];
  };

  const renderReconcileWorkspace = () => {
    const { account, classes } = props;

    return (
      <>
        <Paper elevation={0} className={classes.container}>
          {renderReconcile()}
        </Paper>
        <Divider className={classes.divider} />
        <Paper elevation={0} className={classes.buttonHolderWithTotal}>
          <div className={classes.paperReconcileWorkspace}>
            <div className={classes.divPaddingLeft20}>
              <Typography variant="subtitle2">
                Statement Ending Balance
              </Typography>
              <Typography>
                {formatNumber(props.statementEndBalance, account.currency, '0,00.00')}
              </Typography>
            </div>
            <div className={classes.divPaddingLeft20}>
              <Typography variant="subtitle2">
                Cleared Balance
              </Typography>
              <Typography>
                {formatNumber(reconcileInfo.get('clearedBalance'), account.currency, '0,00.00')}
              </Typography>
            </div>
            <div className={classes.divPaddingLeft20}>
              <Typography variant="subtitle2">
                Difference
              </Typography>
              <Typography>
                {formatNumber(state.difference, account.currency, '0,00.00')}
              </Typography>
            </div>
            <div className={classes.markAllLabel}>
              <Typography variant="subtitle2">
                Mark all as:
              </Typography>
            </div>
            <Button
              className={classes.buttons}
              onClick={clearAll}
              color="primary"
              id="reconcile-clear-all"
            >
              Cleared
            </Button>
            <Typography
              className={classes.separator}
            >
              |
            </Typography>
            <Button
              className={classes.buttons}
              onClick={unclearAll}
              color="primary"
              id="reconcile-unclear-all"
            >
              Uncleared
            </Button>
          </div>
          <Button
            className={classes.buttons}
            onClick={() => closeAndExit('canceled')}
            color="primary"
            id="reconcile-cancel"
          >
            Finish Later
          </Button>
          <Typography
            className={classes.separator}
          >
            |
          </Typography>
          <Button
            className={classes.buttons}
            onClick={onSave}
            name="reconcile-last-tab"
            color="primary"
            id="reconcile-save"
          >
            Finish Reconciliation
          </Button>
        </Paper>
      </>
    );
  };

  const prevReconcileInfo = usePrevious(reconcileInfo);

  useEffectOnce(() => {
    log.log('Reconcile Workspace Initialize');
  });
  
  useEffect(() => {
    if (reconcileInfo !== prevReconcileInfo) {
      setState(calculateState(reconcileInfo, props.statementEndBalance));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconcileInfo, prevReconcileInfo]);

  return (
    <Dialog
      classes={{ paperWidthLg: props.classes.paperWidthMd }}
      open
      maxWidth={false}
      onClose={() => closeAndExit('canceled')}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        {`Reconcile Transactions up to ${DateTime.fromISO(statementEndDate).toFormat('MM/dd/yyyy')}`}
      </DialogTitle>
      {renderReconcileWorkspace()}
    </Dialog>
  );
};

export default compose(QData())(ReconcileWorkspace);
